<div class="text-center pt-1 pb-1 notification-title">
  <div class="m-0">NOTIFICATIONS</div>
</div>
<!--<mat-nav-list class="notification-list" role="list">-->
<!--  &lt;!&ndash; Notification item &ndash;&gt;-->
<!--  <mat-list-item *ngFor="let n of notifications" class="notific-item" role="listitem" routerLinkActive="open">-->
<!--    <mat-icon [color]="n.color" class="notific-icon">{{n.icon}}</mat-icon>-->
<!--    <a [routerLink]="[n.route || '/dashboard']">-->
<!--      <div class="mat-list-text">-->
<!--        <h4 class="message">{{n.message}}</h4>-->
<!--        <small class="time text-muted">{{n.time}}</small>-->
<!--      </div>-->
<!--    </a>-->
<!--  </mat-list-item>-->
<!--</mat-nav-list>-->
<!--<div class="text-center mt-1" *ngIf="notifications.length">-->
<!--  <small><a href="#" (click)="clearAll($event)">Alle Benachrichtigungen löschen</a></small>-->
<!--</div>-->

<!--<mat-nav-list class="notification-list" role="list">-->

<!--  <mat-list-item class="notification-sub-title disable-list-item-hover-grey" style="margin-bottom: 30px">-->
<!--    <img style="width: 30px; margin-right: 12px;!important" [src]="'assets/images/W_zertifikate_dashboard_grey.svg'">-->
<!--    <span style="color: rgb(90, 90, 90);">Zertifikate</span>-->
<!--  </mat-list-item>-->

<!--  <div *ngFor="let cert of expiredCerts">-->

<!--    <mat-list-item *ngIf="cert.user" class="notification-sub-title disable-list-item-hover-grey" style="margin-top: 20px;">-->
<!--      <img style="width: 20px; margin-right: 12px;!important" [src]="'assets/images/W_kunde_grey.svg'">-->
<!--      <span style="color: rgb(90, 90, 90);">{{cert.user.user.firstName }} {{cert.user.user.lastName}}</span>-->
<!--&lt;!&ndash;      <span style="color: rgb(90, 90, 90);">{{expiredCertsUsers[1].user.firstName }} {{expiredCertsUsers[0].user.lastName}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;      <span style="color: rgb(90, 90, 90);">{{getNextUser()}}</span>&ndash;&gt;-->
<!--    </mat-list-item>-->

<!--    <mat-list-item class="notific-item notification-item" role="listitem" routerLinkActive="open">-->

<!--      &lt;!&ndash;    <a [routerLink]="['/dashboard']">&ndash;&gt;-->
<!--      <div class="mat-list-text">-->
<!--        <h4 class="message">{{cert.title}}</h4>-->
<!--        <small class="time">fällig am:-->
<!--          <div style="display: inline; color: rgb(218,55,55);">{{cert.expiryDateGer}}</div>-->
<!--        </small>-->
<!--      </div>-->
<!--      &lt;!&ndash;    </a>&ndash;&gt;-->
<!--    </mat-list-item>-->
<!--  </div>-->
<!--</mat-nav-list>-->

<mat-list class="notification-list">
  <mat-list-item class="notification-list-item"
                 *ngFor="let notification of notifications"
                 routerLink="/checklists/{{notification.referenceId}}">
    <mat-icon matListIcon svgIcon="wodoto-{{notification.referenceType.toLowerCase()}}"></mat-icon>
    <h4 mat-line>{{notification.message}}</h4>
    <p mat-line> {{notification.referenceType}} </p>
    <p mat-line class="notification-item-date"> {{notification.createdGer}} </p>

  </mat-list-item>
</mat-list>
