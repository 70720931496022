import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class IconsModule {


  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.initWodotoIcons();
  }

  initWodotoIcons() {
    this.matIconRegistry.addSvgIcon(
      'wodoto-dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_dashboard_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-customer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_kunde_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-certificate',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_zertifikate_dashboard_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-checklist',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_prueflisten_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-checklist-employee',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_prueflisten_mitarbeiter.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-packinglist',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_packlisten_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-tour',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_touren_dashboard_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-notification',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_sprechblase_grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-notification-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_sprechblase.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_Email.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wodoto-email-fill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/W_Email_fill.svg')
    );
  }
}
