import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileUpdateService {

  profileSubject = new Subject<any>();

  constructor() {}

  getProfileSubject(): Subject<any> {
    return this.profileSubject;
  }
}
