import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class DefaultHeaderHttpInterceptor implements HttpInterceptor {

  constructor(private translate: TranslateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const lang =  this.translate.currentLang ? this.translate.currentLang : this.translate.getBrowserLang();
    const request = req.clone({headers: req.headers.set('Accept-Language', lang)});
    return next.handle(request);
  }
}
