import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromClients from './clients.reducers';
import {IClientsStore} from './clients.reducers';
import {ICompanyClient} from '../model/shadow-user.interface';

export const selectClientsStoreFeature = createFeatureSelector<IClientsStore>(fromClients.clientsFeatureKey);

export const selectFeatureClientList = createSelector(
  selectClientsStoreFeature,
  (state: IClientsStore) => state.clients
);

export const selectFeatureClientFullList = createSelector(
  selectClientsStoreFeature,
  (state: IClientsStore) => state.clientsFull
);

export const selectFeatureClientToDelete = createSelector(
  selectClientsStoreFeature,
  (state: IClientsStore) => state.clientToDelete
);

export const selectFeatureClientByUserId = createSelector(
  selectClientsStoreFeature,
  (state: IClientsStore, props) => state.clientsFull.find(c => c.user.id === props.userId)
);


export const selectFeatureClientByMemberId = createSelector(
  selectClientsStoreFeature,
  (state: IClientsStore, props) => {

    const clientToUpdate: ICompanyClient[] = state.clientsFull.filter(client => client.user.id === props.memberId);

    return clientToUpdate[0];
  }
);

export const selectFeatureClientToUpdate = createSelector(
  selectClientsStoreFeature,
  (state: IClientsStore) => state.clientToUpdate
);
