import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {IBreadcrumb} from './breadcrumb.component';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  breadcrumbSubject = new Subject<IBreadcrumb[]>();

  constructor() {}

  getBreadcrumbSubject(): Subject<IBreadcrumb[]> {
    return this.breadcrumbSubject;
  }
}
