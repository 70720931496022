import {IAuthToken} from './auth-token.interface';

export interface IAuthTokenDecoded {
  companies: IAuthTokenCompanyMembership[];
  roles: string[];
  sub: string;
  inviteKey?: string
  language?: string
}

export interface IAuthTokenCompanyMembership {
  id: number;
  name: string;
  membershipType: string;
}

export const initialCompanyMembership: IAuthTokenCompanyMembership = {
  id: 0,
  name: '',
  membershipType: ''
};

export const initialUserRoles: IAuthTokenDecoded = {
  companies: [initialCompanyMembership],
  roles: [''],
  sub: ''
};
