import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ICompany, ICompanyCreate, ICompanyUser} from '../model/company-user.interface';
import {ILoginCredentials} from '../model/login-credentials.interface';
import {IAuthToken} from '../model/auth-token.interface';
import {Store} from '@ngrx/store';
import {AuthStore} from '../store/authentication.reducers';
import {IAuthTokenDecoded} from '../model/user-roles.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static readonly AUTH_TOKEN_SESSION_STORAGE_KEY = 'auth_token';

  isLoggedIn = false;

  constructor(private router: Router,
              private http: HttpClient,
              private store: Store<{ authentication: AuthStore }>
  ) { }

  login(credentials: ILoginCredentials): Observable<IAuthToken> {

    return this.http.post<IAuthToken>(`${environment.apiBaseUrl}` + '/tokens',
      {
        email: credentials.username,
        password: credentials.password
      },
      { headers: new HttpHeaders({'Content-Type':  'application/json', Accept: '*/*'}) }
    );
  }

  public getUser(authToken: IAuthToken, tokenDecoded: IAuthTokenDecoded): Observable<ICompanyUser> {
    return this.http.get<ICompanyUser>(
      `${environment.apiBaseUrl}` + '/users/' + tokenDecoded.sub
    ).pipe(
      map((companyUser: ICompanyUser) => {
        return companyUser;
      })
    );
  }

  public getCompany(authToken: IAuthToken, companyId: number): Observable<ICompany> {
    return this.http.get<ICompany>(
      `${environment.apiBaseUrl}` + '/companies/' + companyId
    ).pipe(
      map((company: ICompany) => {
        this.isLoggedIn = true;
        return company;
      })
    );
  }

  public updateUser(updatedProfile: ICompanyUser): Observable<ICompanyUser> {
    return this.http.put<ICompanyUser>(`${environment.apiBaseUrl}` + '/users/' + updatedProfile.id, updatedProfile).pipe(map((userResponse) => {
      userResponse.company = updatedProfile.company;
      return userResponse;
    }));
  }

  public updateCompany(updatedProfile: ICompanyUser): Observable<ICompanyUser> {
    return this.http.put<ICompany>(`${environment.apiBaseUrl}` + '/companies/' + updatedProfile.company.id, updatedProfile.company
    ).pipe(map((companyResponse) => {
      updatedProfile.company = companyResponse;
      return updatedProfile;
    }));
  }

  public updateUserPicture(userProfile: ICompanyUser, newProfilePicture: FormData): Observable<any> {
    return this.http.put<any>(`${environment.apiBaseUrl}` + '/users/' + userProfile.id + '/profile-picture', newProfilePicture);
  }

  public updateUserPassword(credentials: ILoginCredentials, companyUser: ICompanyUser): Observable<any> {
    return this.http.put<any>(`${environment.apiBaseUrl}` + '/users/' + companyUser.id + '/password', credentials.passwordChange);
  }

  public createCompanyUser(newCompany: ICompanyCreate): Observable<ICompany> {
    return this.http.post<ICompany>(`${environment.apiBaseUrl}` + '/companies', newCompany);
  }

  public forgotPasswordCompanyUser(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseUrl}` + '/users/' + email + '/password', null);
  }

  public getCompanyUser(companyUserId: number): Observable<ICompanyUser> {
    return this.http.get<ICompanyUser>(
      `${environment.apiBaseUrl}` + '/users/' + companyUserId
    );
  }
}
