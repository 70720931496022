import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppCommonModule} from './components/common/app-common.module';
import {DialogsModule} from './components/dialogs/dialogs.module';
import {RoutePartsService} from './services/route-parts/route-parts.service';
import {NavigationService} from './services/navigation/navigation.service';
import {IconsModule} from './icons/icons.module';
import {UpdateToastComponent} from './snack-bar/update-toast/update-toast.component';



@NgModule({
  declarations: [UpdateToastComponent],
  imports: [
    CommonModule,

    AppCommonModule,
    DialogsModule,
    IconsModule
  ],
  providers: [
    RoutePartsService,
    NavigationService
  ],
  exports: [UpdateToastComponent]
})
export class CoreModule { }
