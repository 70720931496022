import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-success-toast',
  templateUrl: './error-success-toast.component.html',
  styleUrls: ['./error-success-toast.component.css']
})
export class ErrorSuccessToastComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }

  ngOnInit() {
  }

}
