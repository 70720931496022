import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as domHelper from '../../../helpers/dom.helper';
import {select, Store} from '@ngrx/store';
import {selectFeatureCompanyUser} from '../../../../authentication/store/authentication.selector';
import {AuthStore} from '../../../../authentication/store/authentication.reducers';
import {Observable} from 'rxjs';
import {ICompanyUser} from '../../../../authentication/model/company-user.interface';
import {ICert} from '../../../../certs/model/cert.interface';
import {getCompanyClientsList} from '../../../../clients/store/clients.actions';
import {selectFeatureClientFullList} from '../../../../clients/store/clients.selector';
import {ICompanyClient} from '../../../../clients/model/shadow-user.interface';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackComponent} from '../../../../feedback/feedback/feedback.component';
import {AuthService} from '../../../../authentication/service/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {CertsHttpService} from '../../../../certs/service/certs-http.service';
import {logout} from '../../../../authentication/store/authentication.actions';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.template.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() notificationPanel: MatSidenav;
  @Output() onLangChange = new EventEmitter<any>();
  currentLang = 'de';
  availableLangs = [
    {
      name: 'Deutsch',
      code: 'de',
    },
    {
      name: 'English',
      code: 'en',
    },
  ];

  companyUser: Observable<ICompanyUser>;
  companyUserObject: ICompanyUser;

  alerts: number = 0;

  browserLang: string;

  constructor(
    private store: Store<{ authentication: AuthStore }>,
    public translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private certsService: CertsHttpService,
  ) {
  }

  ngOnInit() {
    this.browserLang = this.translate.getBrowserLang();
    this.currentLang = this.browserLang;

    this.companyUser = this.store.pipe(select(selectFeatureCompanyUser));

    this.companyUser.subscribe((updateCompanyUser: ICompanyUser) => {
      this.companyUserObject = updateCompanyUser;
    });

    this.store.dispatch(getCompanyClientsList());
    const clientList = this.store.pipe(select(selectFeatureClientFullList));

    clientList.subscribe((clientsFull: ICompanyClient[]) => {
      clientsFull.forEach(client => {

        this.certsService.getAllCertsForClient(client.user.id).subscribe((certs: ICert[]) => {
          this.alerts = certs.filter(cert => cert.checkAlert === 2).length;

        });
      });

    });
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  toggleNotificationPanel() {
    this.notificationPanel.toggle();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  toggleCollapse() {
    const appBody = document.body;
    domHelper.toggleClass(appBody, 'collapsed-menu');
    domHelper.removeClass(document.getElementsByClassName('has-submenu'), 'open');
  }

  openFeedbackPopup() {
    const dialogFeedback = this.dialog.open(FeedbackComponent, {
      width: '600px',
      maxHeight: '90vh',
      panelClass: 'dialog-container',
      data: {},
    });

    dialogFeedback.afterClosed().subscribe(result => {
    });
  }

  logout(): void {
    sessionStorage.removeItem(AuthService.AUTH_TOKEN_SESSION_STORAGE_KEY);
    this.store.dispatch(logout());
    this.router.navigate(['/sessions/signin']);
  }
}
