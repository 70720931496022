import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {rootRouterConfig} from './app.routes';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AuthenticationModule} from './authentication/authentication.module';
import {EffectsModule} from '@ngrx/effects';
import {DefaultHeaderHttpInterceptor} from './core/interceptors/default-header-http-interceptor.service';
import {VerifyModule} from './verify/verify.module';
import {InvitationModule} from './invitation/invitation.module';
import {InstallComponent} from './install/install.component';
import {FeedbackModule} from './feedback/feedback.module';
import {ErrorModule} from './core/error/error.module';
import {CoreModule} from './core/core.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {StoreDevtools, StoreDevtoolsModule} from '@ngrx/store-devtools';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    CoreModule,

    MatDatepickerModule,
    // MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(rootRouterConfig, { /*enableTracing: true*/}),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      name: 'Wodoto',
    }),
    EffectsModule.forRoot([]),
    // StoreModule.forRoot({auth: reducer}),
    // EffectsModule.forRoot([LoginEffects]),
    AuthenticationModule,
    VerifyModule,
    InvitationModule,
    FeedbackModule,
    ErrorModule
  ],
  declarations: [AppComponent, InstallComponent],
  providers: [

    {provide: HTTP_INTERCEPTORS, useClass: DefaultHeaderHttpInterceptor, multi: true}
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
