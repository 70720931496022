<h1 mat-dialog-title style="font-family: Wodoto;">
  <mat-icon style="vertical-align: middle; color: #00b5ed;">info</mat-icon>
  <p style="margin-left: 15px; display: inline">Info</p>
</h1>

<div mat-dialog-content style="font-family: Wodoto; text-align: justify">
  <p>
    {{data.message}}
  </p>
</div>

<div mat-dialog-actions style="font-family: Wodoto; font-size: 14px;">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial style="font-family: Wodoto;">OK</button>
</div>
