import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

interface IMenuItem {
  type: string;
  name?: string;
  state?: string;
  icon?: string;
  iconLink?: string;
  tooltip?: string;
  disabled?: boolean;
  sub?: IChildItem[];
}

interface IChildItem {
  name: string;
  state: string;
}

@Injectable()
export class NavigationService {

  iconMenu: IMenuItem[] = [
    {
      name: 'SIDE_MENU.DASHBOARD',
      type: 'link',
      tooltip: 'SIDE_MENU.DASHBOARD_TOOLTIP',
      icon: 'dashboard',
      iconLink: 'assets/images/W_dashboard_grey.svg',
      state: 'dashboard'
    },
    {
      name: 'SIDE_MENU.CLIENTS',
      type: 'link',
      tooltip: 'SIDE_MENU.CLIENTS_TOOLTIP',
      icon: 'how_to_reg',
      iconLink: 'assets/images/W_kunde_grey.svg',
      state: 'clients'
    },
    {
      name: 'SIDE_MENU.CERTS',
      type: 'link',
      tooltip: 'SIDE_MENU.CERTS_TOOLTIP',
      icon: 'grade',
      iconLink: 'assets/images/W_zertifikate_dashboard_grey.svg',
      state: 'certs'
    },
    {
      name: 'SIDE_MENU.CHECKLISTS_CLIMBING',
      type: 'link',
      tooltip: 'SIDE_MENU.CHECKLISTS_CLIMBING_TOOLTIP',
      icon: 'list',
      iconLink: 'assets/images/W_prueflisten_mitarbeiter.svg',
      state: 'checklists'
    },
    {
      name: 'SIDE_MENU.CHECKLISTS_COMPANY',
      type: 'link',
      tooltip: 'SIDE_MENU.CHECKLISTS_COMPANY_TOOLTIP',
      icon: 'list',
      iconLink: 'assets/images/W_prueflisten_grey.svg',
      state: 'checklists/company'
    },
    {
      name: 'SIDE_MENU.PACKAGING',
      type: 'link',
      tooltip: 'SIDE_MENU.PACKAGING_TOOLTIP',
      icon: 'work',
      iconLink: 'assets/images/W_packlisten_grey.svg',
      state: 'packaging'
    },
    {
      name: 'SIDE_MENU.ALERTS',
      type: 'link',
      tooltip: 'SIDE_MENU.ALERTS_TOOLTIP',
      icon: 'notifications',
      iconLink: 'assets/images/W_dashboard_grey.svg',
      state: 'alerts'
    },
    {
      name: 'SIDE_MENU.TOURS',
      type: 'link',
      tooltip: 'SIDE_MENU.TOURS_TOOLTIP',
      icon: 'work',
      iconLink: 'assets/images/W_touren_dashboard_grey.svg',
      state: 'tours'
    }
  ];

  iconTypeMenuTitle = 'Frequently Accessed';

  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);

  menuItems$ = this.menuItems.asObservable();

  constructor() {
  }

  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
  }
}
