import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorSuccessToastComponent} from '../core/components/common/snackbar/error-success-toast/error-success-toast.component';
import { AcceptComponent } from './accept/accept.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
  declarations: [AcceptComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatSelectModule,
    ],
  entryComponents: [ErrorSuccessToastComponent]
})
export class InvitationModule { }
