import {Action, createReducer, on} from '@ngrx/store';
import * as ErrorActions from './error.actions';
import {ApiError} from '../api-error.model';


export const initialState: ErrorState = {
  apiError: null
};

export interface ErrorState {
  apiError: ApiError;
}

export const featureKey = 'notification';

const _reducer = createReducer(
  initialState,
  on(ErrorActions.handleGenericApiError, (state, {error}) => ({
    ...state,
    apiError: error
  })),
);

export function reducer(state: ErrorState | undefined, action: Action) {
  return _reducer(state, action);
}
