import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromAuthentication from './store/authentication.reducers';
import {EffectsModule} from '@ngrx/effects';
import {LoginEffects} from './store/authentication.effects';
import {TokenInterceptor} from './service/token-http-interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAuthentication.authenticationFeatureKey, fromAuthentication.reducer),
    EffectsModule.forFeature([LoginEffects])
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}]
})
export class AuthenticationModule { }
