import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UpdateToastComponent} from './update-toast/update-toast.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private defaultDuration = 2000;
  private defaultPanelClasses = ['common-snackbar'];

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  showInfo(message: string, translate: boolean = true): MatSnackBarRef<UpdateToastComponent> {
    return this.showGeneric(message, ['blue-snackbar']);
  }

  showError(message: string, translate: boolean = true): MatSnackBarRef<UpdateToastComponent> {
    return this.showGeneric(message, ['error-snackbar']);
  }

  showGeneric(message: string, customPanelClasses: string[], translate: boolean = true): MatSnackBarRef<UpdateToastComponent> {
    const panelClasses = customPanelClasses ? [...this.defaultPanelClasses, ...customPanelClasses] : this.defaultPanelClasses;

    return this.snackBar.openFromComponent(UpdateToastComponent, {
      duration: this.defaultDuration,
      panelClass: panelClasses,
      data: translate ? this.translate.instant(message) : message,
    });
  }

}
