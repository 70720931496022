import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Pipe({
  name: 'urlAddAuth'
})
export class UrlAddAuthPipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  transform(url): Observable<SafeUrl> {
      return this.http
        .get(url, { responseType: 'blob' }).pipe(
          map(val => {
            return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val));
          }),
          catchError(err => {
            // DEFAULT ICON IF NOTHING COULD BE RECEIVED FROM THE BACKEND
            return of(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/logo/App-Icon-white-1024.png'));
            // return of(this.sanitizer.bypassSecurityTrustResourceUrl('/default'));
          })
        );
  }
}

