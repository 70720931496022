import {createAction, props} from '@ngrx/store';
import {ILoginCredentials, IPasswordChange} from '../model/login-credentials.interface';
import {IAuthToken} from '../model/auth-token.interface';
import {ICompanyCreate, ICompanyUser} from '../model/company-user.interface';
import {IAuthTokenDecoded} from '../model/user-roles.interface';

export const login = createAction(
  '[Login Page] Login',
  props<{ loginCredentials: ILoginCredentials }>()
);

export const logout = createAction(
  '[Login Page] Logout',
);

export const decodeToken = createAction(
  '[Login Page] DecodeToken',
  props<{ authToken: IAuthToken }>()
);

export const loginSuccess = createAction(
  '[Login Page] LoginSuccess',
  props<{ authToken: IAuthToken, userRoles: IAuthTokenDecoded }>()
);

export const loginFailed = createAction(
  '[Login Page] LoginFailed'
);

export const loggedInCompanyUser = createAction(
  '[Login Page] LoggedInCompanyUser',
  props<{ companyUser: ICompanyUser, userRoles: IAuthTokenDecoded, authToken: IAuthToken }>()
);

export const loginCompany = createAction(
  '[Login Page] LoginCompany',
  props<{ companyUser: ICompanyUser, userRoles: IAuthTokenDecoded, authToken: IAuthToken }>()
);

export const loginCompleted = createAction(
  '[Login Page] LoginCompleted',
  props<{ companyUser: ICompanyUser }>()
);

export const updateProfile = createAction(
  '[Update Profile] UpdateProfile',
  props<{ companyUser: ICompanyUser }>()
);

export const updateProfileSuccess = createAction(
  '[Update Profile] UpdateProfileSuccess',
  props<{ companyUser: ICompanyUser }>()
);

export const updateProfileFailed = createAction(
  '[Update Profile] UpdateProfileFailed'
);

export const updateProfilePicture = createAction(
  '[Update Profile] UpdateProfilePicture',
  props<{ companyUser: ICompanyUser, profilePicture: FormData }>()
);

export const updateProfilePictureSuccess = createAction(
  '[Update Profile] UpdateProfilePictureSuccess',
  props<{ companyUser: ICompanyUser }>()
);

export const updateProfilePictureFailed = createAction(
  '[Update Profile] UpdateProfilePictureFailed'
);

export const updateProfilePassword = createAction(
  '[Update Profile] UpdateProfilePassword',
  props<{ loginCredentials: ILoginCredentials, companyUser: ICompanyUser }>()
);

export const updateProfilePasswordSuccess = createAction(
  '[Update Profile] UpdateProfilePasswordSuccess',
  props<{ passwordChange: IPasswordChange }>()
);

export const updateProfilePasswordFailed = createAction(
  '[Update Profile] UpdateProfilePasswordFailed'
);

export const createNewCompany = createAction(
  '[Create Profile] CreateNewCompany',
  props<{ newCompany: ICompanyCreate }>()
);

export const createNewCompanySuccess = createAction(
  '[Create Profile] CreateNewCompanySuccess'
);

export const createNewCompanyFailed = createAction(
  '[Create Profile] CreateNewCompanyFailed'
);

export const createNewCompanyFailedEmailAlreadyExists = createAction(
  '[Create Profile] CreateNewCompanyFailedEmailAlreadyExists'
);

export const forgotPasswordCompanyUser = createAction(
  '[Update Profile] forgotPasswordCompanyUser',
  props<{ emailToReset: string }>()
);

export const forgotPasswordCompanyUserSuccess = createAction(
  '[Update Profile] forgotPasswordCompanyUserSuccess'
);

export const forgotPasswordCompanyUserFailed = createAction(
  '[Update Profile] forgotPasswordCompanyUserFailed'
);

export const getCompanyUser = createAction(
  '[Company User] Get Company User',
  props<{ companyUserId: number }>()
);

export const getCompanyUserSuccess = createAction(
  '[Company User] Get Company User Success',
  props<{ companyUser: ICompanyUser }>()
);

export const getCompanyUserFailed = createAction(
  '[Company User] Get Company User Failed'
);
