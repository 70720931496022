import {Action, createReducer, on} from '@ngrx/store';
import * as LoginPageActions from './authentication.actions';
import {ILoginCredentials, initialStateLoginCredentials} from '../model/login-credentials.interface';
import {IAuthToken, initialStateAuthToken} from '../model/auth-token.interface';
import {
  ICompanyCreate,
  ICompanyUser,
  initialCompanyCreate,
  initialStateCompanyUser
} from '../model/company-user.interface';
import {IAuthTokenDecoded, initialUserRoles} from '../model/user-roles.interface';

export const initialState: AuthStore = {
  loggedIn: undefined,
  loginFailed: false,
  loginCredentials: initialStateLoginCredentials,
  authToken: initialStateAuthToken,
  userRoles: initialUserRoles,
  companyUser: initialStateCompanyUser,
  companyUserCreate: initialCompanyCreate,
  emailToReset: ''
};

export interface AuthStore {
  loggedIn: boolean;
  loginFailed: boolean;
  loginCredentials: ILoginCredentials;
  authToken: IAuthToken;
  userRoles: IAuthTokenDecoded;
  companyUser: ICompanyUser;
  companyUserCreate: ICompanyCreate;
  emailToReset: string;
}

export const authenticationFeatureKey = 'authentication';

export const loginReducer = createReducer(
  initialState,
  on(LoginPageActions.login, (state, {loginCredentials}) => ({
    ...state,
    loginCredentials: loginCredentials
  })),
  on(LoginPageActions.decodeToken, (state, {authToken}) => ({...state})),
  on(LoginPageActions.loginSuccess, (state, {authToken, userRoles}) => ({
    ...state,
    authToken: authToken,
    userRoles: userRoles,
  })),
  on(LoginPageActions.loginCompleted, (state, {companyUser}) => ({
    ...state,
    companyUser: companyUser,
    loggedIn: true,
  })),
  on(LoginPageActions.loginFailed, (state, {}) => ({...initialState, loginFailed: true})),
  on(LoginPageActions.loggedInCompanyUser, (state, {companyUser, userRoles, authToken}) => ({
    ...state,
    companyUser: companyUser
  })),
  on(LoginPageActions.loginCompany, (state, {companyUser, userRoles, authToken}) => ({
    ...state,
    companyUser: companyUser
  })),
  on(LoginPageActions.updateProfile, (state, {}) => ({...state})),
  on(LoginPageActions.updateProfileSuccess, (state, {companyUser}) => ({...state, companyUser: companyUser})),
  on(LoginPageActions.updateProfileFailed, (state, {}) => ({...state})),
  on(LoginPageActions.updateProfilePicture, (state, {companyUser, profilePicture}) => {
      const companyUserTmp = companyUser;
      companyUserTmp.selectedProfilePicture = profilePicture;
      return ({...state, companyUser: companyUserTmp});
    }
  ),
  on(LoginPageActions.updateProfilePictureSuccess, (state, {companyUser}) => ({...state, companyUser: companyUser})),
  on(LoginPageActions.updateProfilePictureFailed, (state, {}) => ({...state})),
  on(LoginPageActions.updateProfilePassword, (state, {loginCredentials}) => {
    const loginCredentialsTmp = state.loginCredentials;
    loginCredentialsTmp.passwordChange = loginCredentials.passwordChange;
    return ({...state, loginCredentials: loginCredentialsTmp});
  }),
  on(LoginPageActions.updateProfilePasswordSuccess, (state, {passwordChange}) => {
    const companyUserTmp = state.companyUser;
    companyUserTmp.password = passwordChange.newPassword;
    return ({...state, companyUser: companyUserTmp});
  }),
  on(LoginPageActions.updateProfilePasswordFailed, (state, {}) => ({...state})),
  on(LoginPageActions.createNewCompany, (state, {newCompany}) => ({...state, companyUserCreate: newCompany})),
  on(LoginPageActions.createNewCompanySuccess, (state, {}) => ({...state})),
  on(LoginPageActions.createNewCompanyFailed, (state, {}) => ({...state})),
  on(LoginPageActions.forgotPasswordCompanyUser, (state, {emailToReset}) => ({...state, emailToReset: emailToReset})),
  on(LoginPageActions.forgotPasswordCompanyUserSuccess, (state, {}) => ({...state})),
  on(LoginPageActions.forgotPasswordCompanyUserFailed, (state, {}) => ({...state})),
  on(LoginPageActions.getCompanyUser, (state, {}) => ({...state})),
  on(LoginPageActions.getCompanyUserSuccess, (state, {companyUser}) => {

    const companyUserTmp = companyUser;
    companyUserTmp.company = state.companyUser.company;
    companyUserTmp.selectedProfilePicture = state.companyUser.selectedProfilePicture;

    return ({...state, companyUser: companyUserTmp});
  }),
  on(LoginPageActions.getCompanyUserFailed, (state, {}) => ({...state})),
  on(LoginPageActions.logout, (state, {}) => (initialState)),
);

export function reducer(state: AuthStore | undefined, action: Action) {
  return loginReducer(state, action);
}
