import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {featureKey, notificationReducer} from './store/notification.reducers';
import {EffectsModule} from '@ngrx/effects';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {Angular2SignaturepadModule} from 'angular2-signaturepad';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationEffects} from './store/notification.effects';
import {ErrorModule} from '../core/error/error.module';
import {NotificationListComponent} from './notification-list/notification-list.component';
import {RouterModule} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    StoreModule.forFeature(featureKey, notificationReducer),
    EffectsModule.forFeature([NotificationEffects]),

    Angular2SignaturepadModule,
    MatProgressSpinnerModule,
    PdfViewerModule,
    TranslateModule,

    ErrorModule,
    MatListModule,
    MatIconModule
  ],
  entryComponents: [],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
  declarations: [
    NotificationListComponent
  ],
  exports: [
    NotificationListComponent
  ]
})
export class NotificationModule {
}
