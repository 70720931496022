import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ErrorActions from './error.actions';

@Injectable()
export class ErrorEffects {

  handleGenericApiError = createEffect(
    () => {
      return this.actions.pipe(
        ofType(ErrorActions.handleGenericApiError),
        tap((action) => {
          // Setting the timeout, so that angular would re-run change detection.
          console.log('handle generic api error', action.error);
          setTimeout(
            () =>
              this.snackBar.open(action.error.errorMessage, 'Error', {
                duration: 2500,
              }),
            0
          );
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private actions: Actions,
    private snackBar: MatSnackBar
  ) {
  }
}
