<mat-nav-list class="" role="list" class="navigation-list mat-elevation-z2">

  <mat-accordion>
    <div *ngFor="let item of menuItems">

      <mat-list-item class="navigation-list-item"
        routerLinkActive="router-link-active"
        *ngIf="item.type === 'link'"
        [ngClass]="{'has-submenu': item.type === 'dropDown'}"
        [matTooltip]="item.tooltip | translate">
        <a routerLink="/{{item.state}}" [class.disable-tours]="item.name === 'SIDE_MENU.TOURS'"
           routerLinkActive="router-link-active" style="padding: 0 16px;">
          <img style="width: 22px; margin-right: 12px;!important" [src]="item.iconLink"
               *ngIf="item.name !== 'SIDE_MENU.ALERTS'">
          <mat-icon style="margin-right: 12px;!important" class="alert-link"
                    *ngIf="item.name === 'SIDE_MENU.ALERTS'">{{ item.icon }}</mat-icon>
          <div>
            <span style="color: rgb(90, 90, 90); display:block;">{{ item.name | translate }}</span>
            <span *ngIf="item.subtitle" class="checklist-sub-title"
                  style="color: rgb(90, 90, 90); display:block;">{{ item.subtitle | translate }}</span></div>
          <img *ngIf="item.name === 'SIDE_MENU.TOURS'"
               style="width: 22px; margin-left: 85px;" [src]="'assets/images/W_lock_dashboard_grey.png'">

        </a>
      </mat-list-item>
    </div>
  </mat-accordion>
</mat-nav-list>
