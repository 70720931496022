import {Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.css']
})
export class InstallComponent implements OnInit {

  private static readonly ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=de.wodoto';
  private static readonly IOS_APP_URL = 'https://apps.apple.com/de/app/wodoto/id1470828212';

  constructor(private meta: Meta, private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get('INSTALL.TITLE').subscribe(result => this.meta.updateTag({name: 'og:title', content: result}));
    this.translate.get('INSTALL.DESCRIPTION').subscribe(result => this.meta.updateTag({name: 'og:description', content: result}));

    this.meta.updateTag({name: 'og:site_name', content: 'WODOTO'});
    this.meta.updateTag({name: 'og:type', content: 'website'});
    this.meta.updateTag({name: 'og:image', content: 'https://www.wodoto.app/wp-content/uploads/2019/09/App-Icon.png'});
    this.meta.updateTag({name: 'og:url', content: 'https://wodoto.app'});

    this.redirectToAppStore();
  }

  // TODO: direct access to "window" could lead to errors in tests because there the window object is maybe not available
  redirectToAppStore(): void {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1 || userAgent.indexOf('ipod') !== -1);
    const isAndroid = userAgent.indexOf('android') !== -1;

    if (isIOS || isAndroid) {
      window.location.assign(isIOS ? InstallComponent.IOS_APP_URL : InstallComponent.ANDROID_APP_URL);
    } else if (navigator.userAgent.match('Mac OS')) {
      window.location.assign(InstallComponent.IOS_APP_URL);
    } else {
      window.location.assign(InstallComponent.ANDROID_APP_URL);
    }
  }
}
