import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../service/auth.service';
import {decodeToken} from '../store/authentication.actions';
import {select, Store} from '@ngrx/store';
import {selectFeatureLoggedIn} from '../store/authentication.selector';
import {map, skipWhile, switchMap} from 'rxjs/operators';
import {AuthStore} from '../store/authentication.reducers';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LandingGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<{ authentication: AuthStore }>,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    const authToken = sessionStorage.getItem(AuthService.AUTH_TOKEN_SESSION_STORAGE_KEY);

    if (authToken) {
      this.store.dispatch(decodeToken({authToken: {bearer: authToken}}));
      this.store.pipe(
        select(selectFeatureLoggedIn),
        skipWhile((isLoggedIn: boolean) => isLoggedIn === null || isLoggedIn === undefined),
        map((isLoggedIn: boolean) => {

          if (isLoggedIn) {
            this.router.navigate(['/dashboard']);
          }
          return isLoggedIn;
        }),
      );

    }

    return true;
  }

}
