export interface IFeedback {
  subject: string;
  recipients: string[];
  body: string;
  files: string[];
}

export interface IFeedbackResponse {
  body: object;
  statusCode: string;
  statusCodeValue: number;
}

export interface IFeedbackCreateIssue {
  description: string;
  key?: string;
  title: string;
  type: string;
}

export enum IFeedbackJiraIssueType {
  BUG, NEW_FEATURE, IMPROVEMENT
}
