import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {INotification, ReferenceType} from './notification.model';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpClient: HttpClient,
              private datePipe: DatePipe) {
  }

  public getNotifications(): Observable<INotification[]> {

    return this.httpClient.get<any>(`${environment.apiBaseUrl}` + '/notifications').pipe(
      map(result => result.elements),
      map((notifications: INotification[]) => notifications.map(notification => {
        notification.createdGer = this.datePipe.transform(new Date(notification.created), 'dd.MM.yyyy');
        // notification.referenceType = ReferenceType[notification.referenceType];
        return notification;
      })),
    );
  }
}
