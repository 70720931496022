import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IFeedback, IFeedbackCreateIssue, IFeedbackResponse} from '../model/feedback.interface';

@Injectable({
  providedIn: 'root'
})
export class FeedbackHttpService {

  constructor(private http: HttpClient) { }

  public sendFeedback(feedback: IFeedback): Observable<IFeedbackResponse> {
    return this.http.post<IFeedbackResponse>(`${environment.apiBaseUrl}` + '/mails', feedback);
  }

  public createFeedbackJiraIssue(feedbackIssue: IFeedbackCreateIssue): Observable<IFeedbackResponse> {
    return this.http.post<IFeedbackResponse>(`${environment.apiBaseUrl}` + '/issues', feedbackIssue);
  }
}
