import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BreadcrumbService} from './breadcrumb.service';
import {Subject} from 'rxjs';

export interface IBreadcrumb {
  title: string;
  route: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  public isEnabled: boolean = true;

  breadcrumbSubject: Subject<IBreadcrumb[]>;

  breadcrumbs: IBreadcrumb[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.breadcrumbSubject = this.breadcrumbService.getBreadcrumbSubject();
  }

  ngOnInit() {
    this.breadcrumbSubject.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
      this.changeDetectorRef.detectChanges();
    });
  }

}
