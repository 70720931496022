import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
// import { TranslateModule } from 'ng2-translate/ng2-translate';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TopbarComponent} from './topbar/topbar.component';
import {NavigationComponent} from './navigation/navigation.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {CommonDirectivesModule} from '../../directives/common/common-directives.module';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonPipesModule} from '../../pipes/common/common-pipes.module';
import {ErrorSuccessToastComponent} from './snackbar/error-success-toast/error-success-toast.component';
import {StoreModule} from '@ngrx/store';
import * as fromClients from '../../../clients/store/clients.reducers';
import {EffectsModule} from '@ngrx/effects';
import {ClientsEffects} from '../../../clients/store/clients.effects';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {NotificationModule} from '../../../notification/notification.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    RouterModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    CommonDirectivesModule,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    CommonPipesModule,
    StoreModule.forFeature(fromClients.clientsFeatureKey, fromClients.reducer),
    EffectsModule.forFeature([ClientsEffects]),

    NotificationModule,
  ],
  declarations: [
    MainLayoutComponent,
    AuthLayoutComponent,
    TopbarComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ErrorSuccessToastComponent,
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    DatePipe,
  ],
  exports: [ErrorSuccessToastComponent],
})
export class AppCommonModule {
}
