import {Action, createReducer, on} from '@ngrx/store';
import * as NotificationActions from './notification.actions';
import {INotification} from '../notification.model';
import {ApiError} from '../../core/error/api-error.model';

export const initialState: NotificationState = {
  notifications: [],
  apiError: null
};

export interface NotificationState {
  notifications: INotification[];
  apiError: ApiError;
}

export const featureKey = 'notification';

const _notificationReducer = createReducer(
  initialState,
  on(NotificationActions.getNotifications, (state, {}) => ({...state})),
  on(NotificationActions.getNotificationsSuccess, (state, {notifications}) => {
    return {
      notifications: notifications,
      apiError: null
    };
  }),
);

export function notificationReducer(state: NotificationState | undefined, action: Action) {
  return _notificationReducer(state, action);
}
