import {createAction, props} from '@ngrx/store';
import {INotification} from '../notification.model';
import {ApiError} from '../../core/error/api-error.model';

export const getNotifications = createAction(
  '[Notification] Get Notifications',
  props<{ companyId: number }>()
);

export const getNotificationsSuccess = createAction(
  '[Notification] Get Notifications Success',
  props<{ notifications: INotification[] }>()
);
