import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ICert, ICertTypes} from '../model/cert.interface';

@Injectable({
  providedIn: 'root',
})
export class CertsHttpService {

  constructor(private http: HttpClient) {
  }

  public addNewCertToClient(clientId: number, cert: ICert): Observable<ICert> {
    return this.http.post<ICert>(`${environment.apiBaseUrl}` + '/users/' + clientId + '/certificates', cert);
  }

  public addCertFile(clientId: number, certId: number, certFile: FormData): Observable<any> {
    return this.http.put<any>(`${environment.apiBaseUrl}` + '/users/' + clientId + '/certificates/' + certId + '/image', certFile);
  }

  public getAllCertsForClient(clientId: number): Observable<ICert[]> {
    return this.http.get<ICert[]>(`${environment.apiBaseUrl}` + '/users/' + clientId + '/certificates');
  }

  public deleteCertFromClient(clientId: number, certId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiBaseUrl}` + '/users/' + clientId + '/certificates/' + certId);
  }

  public updateCertForClient(clientId: number, certId: number, cert: ICert): Observable<ICert> {
    return this.http.put<ICert>(`${environment.apiBaseUrl}` + '/users/' + clientId + '/certificates/' + certId, cert);
  }

  public getAllCertTypes(): Observable<ICertTypes[]> {
    return this.http.get<ICertTypes[]>(`${environment.apiBaseUrl}` + '/certificates');
  }
}
