import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {select, Store} from '@ngrx/store';
import {first, flatMap} from 'rxjs/operators';
import {selectFeatureAuthToken} from '../store/authentication.selector';
import {AuthStore} from '../store/authentication.reducers';

export const AuthorizationHeader = 'Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private store: Store<{ authentication: AuthStore }>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (req.headers.has(AuthorizationHeader)) {
      return next.handle(req.clone());
    } else {
      return this.store.pipe(
        select(selectFeatureAuthToken),
        first(),
        flatMap(token => {
          const authReq = !!token ? req.clone({
            setHeaders: {Authorization: 'Bearer ' + token.bearer},
          }) : req;
          return next.handle(authReq);
        }),
      );
    }
  }
}
