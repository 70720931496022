import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthService} from '../service/auth.service';
import {AuthStore} from './authentication.reducers';
import {ICompany, ICompanyUser} from '../model/company-user.interface';
import * as jwt from 'jsonwebtoken';
import {IAuthTokenDecoded} from '../model/user-roles.interface';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {
  createNewCompany,
  createNewCompanyFailed,
  createNewCompanyFailedEmailAlreadyExists,
  createNewCompanySuccess,
  forgotPasswordCompanyUser,
  forgotPasswordCompanyUserFailed,
  forgotPasswordCompanyUserSuccess,
  getCompanyUser,
  getCompanyUserFailed,
  getCompanyUserSuccess,
  updateProfile,
  updateProfileFailed,
  updateProfilePassword,
  updateProfilePasswordFailed,
  updateProfilePasswordSuccess,
  updateProfilePicture,
  updateProfilePictureFailed,
  updateProfilePictureSuccess,
  updateProfileSuccess
} from './authentication.actions';

@Injectable()
export class LoginEffects {

  loginWebApp = createEffect(() =>
    this.actions.pipe(
      ofType('[Login Page] Login'),
      mergeMap((authStore: AuthStore) => {
        return this.authService.login(authStore.loginCredentials)
          .pipe(
            map((authToken) => ({
              type: '[Login Page] DecodeToken',
              authToken: authToken
            })),
            catchError(() => of({type: '[Login Page] LoginFailed'}))
          );
      })
    )
  );

  tokenDecodeWebApp = createEffect(() =>
    this.actions.pipe(
      ofType('[Login Page] DecodeToken'),
      mergeMap((authStore: AuthStore) => {

        const jwtDecoded = jwt.decode(authStore.authToken.bearer, {complete: true});
        const tokenDecoded: IAuthTokenDecoded = jwtDecoded.payload;

        if (jwtDecoded === undefined || tokenDecoded === undefined) {
          sessionStorage.removeItem(AuthService.AUTH_TOKEN_SESSION_STORAGE_KEY);
          return of({type: '[Login Page] LoginFailed'});
        }

        sessionStorage.setItem(AuthService.AUTH_TOKEN_SESSION_STORAGE_KEY, authStore.authToken.bearer);
        return of({
          type: '[Login Page] LoginSuccess',
          authToken: authStore.authToken,
          userRoles: tokenDecoded
        });
      })
    )
  );

  getUserWebApp = createEffect(() =>
    this.actions.pipe(
      ofType('[Login Page] LoginSuccess'),
      mergeMap((authStore: AuthStore) => {
        return this.authService.getUser(authStore.authToken, authStore.userRoles)
          .pipe(
            map((companyUser: ICompanyUser) => ({
              type: '[Login Page] LoggedInCompanyUser',
              companyUser: companyUser,
              userRoles: authStore.userRoles,
              authToken: authStore.authToken
            })),
            catchError(() => of({type: '[Login Page] LoginFailed'}))
          );
      })
    )
  );

  setCompanyUserWebApp = createEffect(() =>
    this.actions.pipe(
      ofType('[Login Page] LoggedInCompanyUser'),
      mergeMap((authStore: AuthStore) => {

        let companyUser: ICompanyUser = authStore.companyUser;

        if (companyUser === undefined) {
          return of({type: '[Login Page] LoginFailed'});
        }

        companyUser = {
          ...companyUser,
          companyUser: authStore.userRoles.roles.includes('COMPANY_ADMIN')
        };

        return of({
          type: '[Login Page] LoginCompany',
          companyUser: companyUser,
          userRoles: authStore.userRoles,
          authToken: authStore.authToken
        });
      })
    )
  );

  getCompanyWebApp = createEffect(() =>
    this.actions.pipe(
      ofType('[Login Page] LoginCompany'),
      mergeMap((authStore: AuthStore) => {

       if (!authStore.companyUser.companyUser || authStore.userRoles.companies.length === 0 || !authStore.userRoles.roles.includes('COMPANY_ADMIN')) {
          return of({type: '[Login Page] LoginFailed'});
        }

        return this.authService.getCompany(authStore.authToken, authStore.userRoles.companies[0].id)
          .pipe(
            map((company: ICompany) => {
              let companyUser: ICompanyUser = authStore.companyUser;
              companyUser = {
                ...companyUser,
                company: company,
              };
              return ({type: '[Login Page] LoginCompleted', companyUser: companyUser});
            }),
            catchError((error) => of({type: '[Login Page] LoginFailed'})
          ));
      })
    )
  );

  // updateUserWebApp = createEffect(() =>
  //   this.actions.pipe(
  //     ofType('[Update Profile] UpdateProfile'),
  //     mergeMap((authStore: AuthStore) => {
  //       return this.authService.updateCompany(authStore.companyUser)
  //         .pipe(
  //           switchMap((companyUser: ICompanyUser) => this.authService.updateUser(companyUser)),
  //           map((companyUser: ICompanyUser) => {
  //             return ({ type: '[Update Profile] UpdateProfileSuccess', companyUser: companyUser });
  //           }),
  //           catchError((error) => {
  //             return of({ type: '[Update Profile] UpdateProfileFailed'});
  //           })
  //         );
  //     })
  //   )
  // );

  updateUserWebApp = createEffect(() =>
    this.actions.pipe(
      ofType(updateProfile),
      mergeMap((action) => {
        return this.authService.updateCompany(action.companyUser)
          .pipe(
            switchMap((companyUser: ICompanyUser) => this.authService.updateUser(action.companyUser)),
            map((companyUser: ICompanyUser) => {
              return ({type: updateProfileSuccess.type, companyUser: companyUser});
            }),
            catchError((error) => {
              return of({type: updateProfileFailed.type});
            })
          );
      })
    )
  );

  // updateUserPictureWebApp = createEffect(() =>
  //   this.actions.pipe(
  //     ofType('[Update Profile] UpdateProfilePicture'),
  //     mergeMap((authStore: AuthStore) => {
  //       return this.authService.updateUserPicture(authStore.companyUser, authStore.companyUser.selectedProfilePicture)
  //         .pipe(
  //           // switchMap(() => this.authService.getUser(authStore.loginCredentials, authStore.authToken)),
  //           // map((companyUser: ICompanyUser) => ({ type: '[Update Profile] UpdateProfilePictureSuccess', companyUser: companyUser})),
  //           map(() => ({ type: '[Update Profile] UpdateProfilePictureSuccess', companyUser: authStore.companyUser})),
  //           catchError(() => of({type: '[Update Profile] UpdateProfilePictureFailed'}))
  //         )
  //     })
  //   )
  // );

  updateUserPictureWebApp = createEffect(() =>
    this.actions.pipe(
      ofType(updateProfilePicture),
      mergeMap((action) => {
        return this.authService.updateUserPicture(action.companyUser, action.profilePicture)
          .pipe(
            map(() => ({type: updateProfilePictureSuccess.type, companyUser: action.companyUser})),
            catchError(() => of({type: updateProfilePictureFailed.type}))
          );
      })
    )
  );

  updateUserPasswordWebApp = createEffect(() =>
    this.actions.pipe(
      ofType(updateProfilePassword),
      mergeMap((action) => {
        return this.authService.updateUserPassword(action.loginCredentials, action.companyUser)
          .pipe(
            map(() => ({
              type: updateProfilePasswordSuccess.type,
              passwordChange: action.loginCredentials.passwordChange
            })),
            catchError(() => of({type: updateProfilePasswordFailed.type}))
          );
      })
    )
  );

  // createNewCompanyWebApp = createEffect(() =>
  //   this.actions.pipe(
  //     ofType('[Create Profile] CreateNewCompany'),
  //     mergeMap((authStore: AuthStore) => {
  //       console.log('EFF CREATE: ' + authStore.companyUserCreate.email);
  //
  //       return this.authService.createCompanyUser(authStore.companyUserCreate)
  //         .pipe(
  //           map(() => ({ type: '[Create Profile] CreateNewCompanySuccess', newCompany: authStore.companyUserCreate})),
  //           catchError(() => of({type: '[Create Profile] CreateNewCompanyFailed'}))
  //         )
  //     })
  //   )
  // );

  // createNewCompanyWebApp = createEffect(() =>
  //   this.actions.pipe(
  //     ofType('[Create Profile] CreateNewCompany'),
  //     switchMap( () => {
  //       // TODO fix buf, authStore undefinded
  //       return this.store.pipe(select(selectCreateCompanyUser));
  //     }),
  //     switchMap((newCompany: ICompanyCreate) => {
  //       return this.authService.createCompanyUser(newCompany)
  //         .pipe(
  //           map(() => ({ type: '[Create Profile] CreateNewCompanySuccess'})),
  //           catchError(() => of({type: '[Create Profile] CreateNewCompanyFailed'}))
  //         )
  //     }),
  //   )
  // );

  createNewCompanyWebApp = createEffect(() =>
    this.actions.pipe(
      ofType(createNewCompany),
      switchMap((action) => {
        return this.authService.createCompanyUser(action.newCompany)
          .pipe(
            map(() => ({type: createNewCompanySuccess.type})),
            catchError((error) => {
              console.log(error);
              if (error.error.errorCode.includes('DUPLICATED_EMAIL')) {
                return of({type: createNewCompanyFailedEmailAlreadyExists.type});
              } else {
                return of({type: createNewCompanyFailed.type});
              }
            })
          );
      })
    )
  );

  forgotPasswordWebApp = createEffect(() =>
    this.actions.pipe(
      ofType(forgotPasswordCompanyUser),
      mergeMap((action) => {
        return this.authService.forgotPasswordCompanyUser(action.emailToReset)
          .pipe(
            map(() => ({type: forgotPasswordCompanyUserSuccess.type})),
            catchError(() => of({type: forgotPasswordCompanyUserFailed.type}))
          );
      })
    )
  );

  getCompanyUserWebApp = createEffect(() =>
    this.actions.pipe(
      ofType(getCompanyUser),
      mergeMap((action) => {
        return this.authService.getCompanyUser(action.companyUserId)
          .pipe(
            map((companyUser: ICompanyUser) => ({
              type: getCompanyUserSuccess.type,
              companyUser: companyUser
            })),
            catchError(() => of({type: getCompanyUserFailed.type}))
          );
      })
    )
  );

  constructor(
    private actions: Actions,
    private authService: AuthService,
  ) {
  }
}
