<div class="page-wrap height-100 mat-bg-primary">
  <div class="session-form-hold" *ngIf="!activateShadowUser">
    <mat-card>
      <mat-card-content>
        <div class="loading-shade" *ngIf="isLoggingIn">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="text-center pb-1">
          <div class="full-wodoto">
            <span style="font-family: 'wodoto-title'; font-size: 40px; color: #00b5ed; margin-top: 10px;">WODOTO</span>
          </div>
          <p class="text-muted m-0">{{ 'COMPANY_INVITATION_ACCEPT.TITLE' | translate }}</p>
        </div>
        <form (ngSubmit)="login()">

          <div class="">
            <mat-form-field class="full-width">
              <input
                matInput
                name="email"
                [formControl]="acceptInvitationForm.controls['email']"
                placeholder="{{ 'COMPANY_INVITATION_ACCEPT.EMAIL' | translate }}"
                value="">
            </mat-form-field>
            <small
              *ngIf="acceptInvitationForm.controls['email'].hasError('required') && acceptInvitationForm.controls['email'].touched"
              class="form-error-msg">{{ 'COMPANY_INVITATION_ACCEPT.EMAIL_ERROR' | translate }}
            </small>
          </div>

          <div class="">
            <mat-form-field class="full-width">
              <input
                type="password"
                name="password"
                matInput
                [formControl]="acceptInvitationForm.controls['password']"
                placeholder="{{ 'COMPANY_INVITATION_ACCEPT.PASSWORD' | translate }}"
                value="">
            </mat-form-field>
            <small
              *ngIf="acceptInvitationForm.controls['password'].hasError('required') && acceptInvitationForm.controls['password'].touched"
              class="form-error-msg"> {{ 'COMPANY_INVITATION_ACCEPT.PASSWORD_ERROR' | translate }}
            </small>
          </div>

          <button mat-raised-button
                  class="mat-primary full-width mb-1"
                  class="submit-button"
                  [disabled]="acceptInvitationForm.invalid">{{ 'COMPANY_INVITATION_ACCEPT.SUBMIT_BTN' | translate }}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="session-form-hold" *ngIf="activateShadowUser">
    <mat-card>
      <mat-card-content>
        <div class="loading-shade" *ngIf="isLoggingIn">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="text-center pb-1">
          <div class="full-wodoto">
            <img src="../../../assets/images/App-Icon-white-1024_org.png" height="40px" alt="" style="margin-right: 8px;">
            <span style="font-family: 'wodoto-title'; font-size: 36px;">WODOTO</span>
          </div>
          <p class="text-muted m-0">{{ 'SIGNUP_APP.TITLE' | translate }}</p>
        </div>
        <form [formGroup]="signupFormGroup" (ngSubmit)="signUpAppUser()">

          <div class="">
            <mat-form-field class="full-width">
              <input
                matInput
                formControlName="firstname"
                name="firstname"
                placeholder="{{ 'SIGNUP_APP.FIRSTNAME' | translate }}"
                value="">
              <mat-error *ngIf="signupFormGroup.controls['firstname'].hasError('required')">
                {{ 'SIGNUP_APP.FIRSTNAME_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field class="full-width">
              <input
                matInput
                formControlName="lastname"
                name="lastname"
                placeholder="{{ 'SIGNUP_APP.LASTNAME' | translate }}"
                value="">
              <mat-error *ngIf="signupFormGroup.controls['lastname'].hasError('required')">
                {{ 'SIGNUP_APP.LASTNAME_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field class="full-width">
              <mat-select
                placeholder="{{ 'SIGNUP_APP.COUNTRY' | translate }}"
                name="country"
                formControlName="country">
                <mat-option
                  *ngFor="let lang of availableLangs"
                  [value]="lang.code" ngDefaultControl>{{ lang.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="signupFormGroup.controls['country'].hasError('required')">
                {{ 'SIGNUP_APP.COUNTRY_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field class="full-width">
              <input
                matInput
                type="email"
                name="email"
                formControlName="email"
                placeholder="{{ 'SIGNUP_APP.EMAIL' | translate }}"
                value="">
              <mat-error *ngIf="signupFormGroup.controls['email'].hasError('required')">
                {{ 'SIGNUP_APP.EMAIL_ERROR_1' | translate }}
              </mat-error>
              <mat-error *ngIf="signupFormGroup.controls['email'].hasError('email')">
                {{ 'SIGNUP_APP.EMAIL_ERROR_2' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field class="full-width">
              <input
                type="password"
                name="password"
                matInput
                formControlName="password"
                placeholder="{{ 'SIGNUP_APP.USER_PASSWORD' | translate }}"
                value="">
              <mat-error *ngIf="signupFormGroup.controls['password'].hasError('required')">
                {{ 'SIGNUP_APP.USER_PASSWORD_ERROR_1' | translate }}
              </mat-error>
              <mat-error *ngIf="signupFormGroup.controls['password'].hasError('strong')">
                {{ 'SIGNUP_APP.USER_PASSWORD_ERROR_2' | translate }}
              </mat-error>
              <mat-error *ngIf="signupFormGroup.controls['password'].hasError('minlength')">
                {{ 'SIGNUP_APP.USER_PASSWORD_ERROR_3' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field class="full-width">
              <input
                type="password"
                name="confirmPassword"
                matInput
                formControlName="confirmPassword"
                placeholder="{{ 'SIGNUP_APP.USER_PASSWORD_CONFIRM' | translate }}"
                value="">
              <mat-error *ngIf="signupFormGroup.controls['confirmPassword'].hasError('mustMatch')">
                {{ 'SIGNUP_APP.USER_PASSWORD_CONFIRM_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <button mat-raised-button
                  class="mat-primary full-width mb-1"
                  class="submit-button"
                  [disabled]="signupFormGroup.invalid">{{ 'SIGNUP_APP.SUBMIT_BTN' | translate }}</button>

        </form>
      </mat-card-content>
    </mat-card>
  </div>

</div>
