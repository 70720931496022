import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../services/navigation/navigation.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.template.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{

  iconTypeMenuTitle: string;

  menuItems: any[];

  menuItemsLast: any[];

  panelOpenState = false;

  constructor(private navService: NavigationService) {
  }

  ngOnInit(): void {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

    this.navService.menuItems$.subscribe(menuItems => {
      this.menuItems = menuItems;
    });
  }

}
