import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSuccessToastComponent} from '../../core/components/common/snackbar/error-success-toast/error-success-toast.component';
import {IAuthTokenDecoded} from '../../authentication/model/user-roles.interface';
import {TranslateService} from '@ngx-translate/core';
import * as jwt from 'jsonwebtoken';
import {IVerifyEmail} from '../verify.interface';

// https://www.wodoto.app

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  verifyForm: FormGroup;

  userId: number;
  userEmail: string;
  token: string;

  private http: HttpClient;

  constructor(private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              handler: HttpBackend,
              private translate: TranslateService,
              private router: Router) {
    this.http = new HttpClient(handler);
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.userId = +params['userId'];
      this.userEmail = params['userEmail'];
    });

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];

      const jwtDecoded = jwt.decode(this.token, {complete: true});
      const tokenDecoded: IAuthTokenDecoded = jwtDecoded.payload;

      if (tokenDecoded.language !== undefined) {
        const languageFull = tokenDecoded.language;
        const language = languageFull.substr(0, 2);
        this.translate.use(language);
      } else {
        this.translate.use('de');
      }
    });

    this.verifyForm = new FormGroup({
      code: new FormControl('', Validators.required)
    });
  }

  verify() {
    const code = this.verifyForm.value.code;

    const verifyEmail: IVerifyEmail = {
      email: this.userEmail,
      verificationCode: code
    };

    this.http.put<any>(`${environment.apiBaseUrl}` + '/users/' + this.userId + '/verify', verifyEmail,
      {headers: new HttpHeaders({'Authorization': `Bearer ${this.token}`})}
    ).subscribe(
      (response) => {
        this.snackBar.openFromComponent(ErrorSuccessToastComponent, {
          duration: 2000,
          panelClass: ['common-snackbar', 'blue-snackbar'],
          data: this.translate.instant('VERIFY_EMAIL.SUBMIT_TOAST_SUCCESS')
        });
        // window.open('https://www.wodoto.app','_self');
      },
      (error) => {
        this.snackBar.openFromComponent(ErrorSuccessToastComponent, {
          duration: 2000,
          panelClass: ['common-snackbar', 'error-snackbar'],
          data: this.translate.instant('VERIFY_EMAIL.SUBMIT_TOAST_FAILED')
        });
      });
  }

}
