<h1 mat-dialog-title style="font-family: Wodoto;">
  {{data.title}}
  <mat-icon *ngIf="data.icon.add" style="margin-left: 20px; vertical-align: middle; color: #0068A9;">{{data.icon.name}}</mat-icon>
</h1>

<div mat-dialog-content style="font-family: Wodoto;">
  <p>
    {{data.message}}
  </p>
</div>

<div mat-dialog-actions style="font-family: Wodoto; font-size: 14px;">
  <button mat-button [mat-dialog-close]="false" style="font-family: Wodoto; rgba(0, 0, 0, 0.32);">{{data.buttons.decline}}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial style="color: red; font-family: Wodoto;">{{data.buttons.accept}}</button>
</div>

<!--rgba(0, 0, 0, 0.32);-->
