import {Action, createReducer, on} from '@ngrx/store';
import * as ClientsActions from './clients.actions';
import {ICompanyClient, initialClientsState, IShadowUser} from '../model/shadow-user.interface';

export interface IClientsStore {
  clients: IShadowUser[];
  clientsFull: ICompanyClient[];
  clientToDelete?: number;
  clientToUpdate?: ICompanyClient;
}

export const clientsFeatureKey = 'clients';

export const clientsReducer = createReducer(
  initialClientsState,
  on(ClientsActions.addCompanyClient, (state, {newClient}) => {

    // let dateIso = new Date(newClient.);
    // checklistItem.checkedGer = this.datePipe.transform(dateIso, 'dd.MM.yyyy');


    let clientsTmp: IShadowUser[] = state.clients;
    clientsTmp.push(newClient);

    return ({...state, clients: clientsTmp})
  }),
  on(ClientsActions.addCompanyClientSuccess, (state, {}) => ({...state})),
  on(ClientsActions.addCompanyClientFailed, (state, {}) => ({...state})),
  on(ClientsActions.getCompanyClientsList, (state, {}) => ({...state})),
  on(ClientsActions.getCompanyClientsListSuccess, (state, { allClients }) => {
    const clients: IShadowUser[] = allClients.map(clientFull => clientFull.user);
    return ({...state, clientsFull: allClients, clients: clients});
  }),
  on(ClientsActions.getCompanyClientsListFailed, (state, {}) => ({...state})),
  on(ClientsActions.getCompanyClientsListTransformDateSuccess, (state, { allClients }) => {
    const clients: IShadowUser[] = allClients.map(clientFull => clientFull.user);
    return ({...state, clientsFull: allClients, clients: clients})
  }),
  on(ClientsActions.getCompanyHasClientAChecklistSuccess, (state, { allClients }) => {
    const clients: IShadowUser[] = allClients.map(clientFull => clientFull.user);
    return ({...state, clientsFull: allClients, clients: clients})
  }),
  on(ClientsActions.getCompanyHasClientAPacklistSuccess, (state, { allClients }) => {
    const clients: IShadowUser[] = allClients.map(clientFull => clientFull.user);
    return ({...state, clientsFull: allClients, clients: clients})
  }),
  on(ClientsActions.deleteCompanyClient, (state, {clientId}) => {

    let clientsTmp: IShadowUser[] = state.clients;
    clientsTmp = clientsTmp.filter(shadowUser => shadowUser.id !== clientId);

    let clientsFullTmp: ICompanyClient[] = state.clientsFull;
    clientsFullTmp = clientsFullTmp.filter(companyClientFull => companyClientFull.user.id !== clientId);

    return ({...state, clients: clientsTmp, clientsFull: clientsFullTmp, clientToDelete: clientId})
  }),
  on(ClientsActions.deleteCompanyClientSuccess, (state, {}) => ({...state})),
  on(ClientsActions.deleteCompanyClientFailed, (state, {}) => ({...state})),
  on(ClientsActions.updateCompanyClient, (state, {updateClientId, updateClient}) => {

    let clientsFullTmp: ICompanyClient[] = state.clientsFull;
    let index = clientsFullTmp.indexOf(updateClient);
    clientsFullTmp[index] = updateClient;

    return ({...state, clientsFull: clientsFullTmp, clientToUpdate: updateClient})
  }),
  on(ClientsActions.updateCompanyClientSuccess, (state, {}) => ({...state})),
  on(ClientsActions.updateCompanyClientFailed, (state, {}) => ({...state})),
);

export function reducer(state: IClientsStore | undefined, action: Action) {
  return clientsReducer(state, action);
}
