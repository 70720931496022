import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {featureKey, reducer} from './store/error.reducers';
import {ErrorEffects} from './store/error.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSnackBarModule,
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([ErrorEffects]),
  ]
})
export class ErrorModule { }
