import {Routes} from '@angular/router';
import {MainLayoutComponent} from './core/components/common/layouts/main-layout/main-layout.component';
import {AuthLayoutComponent} from './core/components/common/layouts/auth-layout/auth-layout.component';
import {AuthGuard} from './authentication/guards/auth.guard';
import {EmailComponent} from './verify/email/email.component';
import {AcceptComponent} from './invitation/accept/accept.component';
import {InstallComponent} from './install/install.component';
import {LandingGuard} from './authentication/guards/landing.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [LandingGuard],
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./sessions/sessions.module').then(m => m.SessionsModule),
        data: {title: 'Session'}
      }
    ]
  },
  {
    path: 'install',
    component: InstallComponent,
    pathMatch: 'full'
  },
  {
    path: 'users/:userId/verify/:userEmail',
    component: EmailComponent
    // children: [
    //   {
    //     path: '',
    //     loadChildren: () => import('./views/verify/verify.module').then(m => m.VerifyModule),
    //     data: { title: 'Verify', breadcrumb: 'VERIFY'}
    //   }
    // ]
  },
  {
    path: 'companies/:companyId/invite',
    component: AcceptComponent
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {title: 'Dashboard', breadcrumb: 'DASHBOARD'}
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        data: {title: 'Profil', breadcrumb: 'PROFILE'}
      },
      {
        path: 'clients',
        loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
        data: {title: 'Mitarbeiter', breadcrumb: 'CLIENTS'}
      },
      {
        path: 'certs',
        loadChildren: () => import('./certs/certs.module').then(m => m.CertsModule),
        data: {title: 'Zertifikate', breadcrumb: 'CERTS'}
      },
      {
        path: 'checklists',
        loadChildren: () => import('./checklists/checklists.module').then(m => m.ChecklistsModule),
        data: {title: 'Prüflisten', breadcrumb: 'CHECKLISTS'}
      },
      {
        path: 'packaging',
        loadChildren: () => import('./packaging/packaging.module').then(m => m.PackagingModule),
        data: {title: 'Packlisten', breadcrumb: 'PACKAGING'}
      },
      {
        path: 'alerts',
        loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule),
        data: {title: 'Alerts', breadcrumb: 'ALERTS'}
      }
    ]
  },
  // {
  //   path: '**',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full'
  // }
];
