export interface INotification {
  id: number;
  created: string;
  createdGer: String;
  message: string;
  referenceType: ReferenceType;
  referenceId: number;
  severity: SeverityType;
  payload: any;
  read: boolean;
  link?: string;
  userId?: number;
}

export enum SeverityType {
  INFO = 'INFO',
  CRITICAL = 'CRITICAL'
}

export enum ReferenceType {
  TEAM_MESSAGE = 'TeamMessage',
  USER_MESSAGE = 'UserMessage',
  TEAM = 'Team',
  USER = 'User',
  CERTIFICATE = 'Certificate',
  CHECKLIST = 'Checklist',
  NOTIFICATION = 'Notification'
}
