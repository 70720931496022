import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IClientsStore} from '../../clients/store/clients.reducers';
import {selectNotifications} from '../store/notification.selector';
import {NotificationState} from '../store/notification.reducers';
import {getNotifications} from '../store/notification.actions';
import {INotification, ReferenceType} from '../notification.model';

interface IDashboardMessageBoard {
  date: string;
  message: string;
  link: string;
  iconUrl: string;
}

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  @Input() notificPanel;

  isLoadingNotifications = true;

  dashboardMessageBoardAll: IDashboardMessageBoard[] = [];

  notifications: INotification[];
  referenceType = ReferenceType;
  ReferenceType = ReferenceType;

  constructor(
    private router: Router,
    private store: Store<{ clients: IClientsStore }>,
    private notificationStore: Store<NotificationState>,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });

    this.notificationStore.dispatch(getNotifications({companyId: 0}));
    this.notificationStore.pipe(select(selectNotifications)).subscribe(
      (notifications: INotification[]) => {

        this.notifications = notifications.filter((notification: INotification) => notification.referenceType !== ReferenceType.TEAM)
          .filter((notification: INotification) => notification.referenceType !== ReferenceType.USER)
          .filter((notification: INotification) => notification.referenceType !== ReferenceType.TEAM_MESSAGE)
          .filter((notification: INotification) => notification.referenceType !== ReferenceType.USER_MESSAGE);

        this.dashboardMessageBoardAll = this.notifications
          .map((notification: INotification) => {
            // if (notification.referenceType.toString() === IReferenceType.CERTIFICATE) {
            //   notification.link = this.getLinkToCertByCertId(notification.referenceId);
            // }
            //
            // if (notification.referenceType.toString() === IReferenceType.CHECKLIST) {
            //   notification.link = this.getLinkToCertByChecklistId(notification.referenceId);
            // }

            // let iconUrl = "";
            // switch (notification.referenceType) {
            //   case ReferenceType.CERTIFICATE:
            //     iconUrl =
            // }

            return {
              date: notification.createdGer,
              message: notification.message,
              link: notification.link === undefined ? '' : notification.link,
            } as IDashboardMessageBoard;
          });
        this.isLoadingNotifications = false;
      },
      error => this.isLoadingNotifications = false,
    );
  }

  navigateMsgLeft() {
    // if (this.dashboardMessageBoardCurrentPage !== 1) {
    //   this.dashboardMessageBoardCurrentPage--;
    //   const dashboardMessageBoardAllCloned = Object.assign([], this.dashboardMessageBoardAll);
    //   this.dashboardMessageBoard = dashboardMessageBoardAllCloned.splice(
    //     (this.dashboardMessageBoardCurrentPage - 1) * this.numberOfMessages,
    //     this.numberOfMessages);
    // }
  }

  navigateMsgRight() {
    // if (this.dashboardMessageBoardCurrentPage < this.dashboardMessageBoardPages) {
    //   this.dashboardMessageBoardCurrentPage++;
    //   const dashboardMessageBoardAllCloned = Object.assign([], this.dashboardMessageBoardAll);
    //   this.dashboardMessageBoard = dashboardMessageBoardAllCloned.splice(
    //     (this.dashboardMessageBoardCurrentPage - 1) * this.numberOfMessages,
    //     this.numberOfMessages);
    // }
  }


  // getLinkToCertByCertId(certId: number): string {
  //   let link = '';
  //
  //   this.employeesWithCerts.forEach(employee => {
  //     const cert = employee.certs.find(c => c.id === certId);
  //     if (cert !== undefined) {
  //       link = '/certs/client/' + employee.user.id;
  //     }
  //   });
  //   return link;
  // }
  //
  // // TODO: dispatch store action
  // getLinkToCertByChecklistId(checklistId: number): string {
  //   let link = '';
  //
  //   this.employeesWithChecklists.forEach(employee => {
  //     const checklist = employee.checklists.find(c => c.id === checklistId);
  //     if (checklist !== undefined) {
  //
  //       if (checklist.type.toString() === ChecklistType.GENERIC) {
  //         link = '/checklists/client/' + employee.user.id + '/items/' + checklistId + '/' + checklist.name;
  //       } else if (checklist.type.toString() === ChecklistType.MEDICAL) {
  //         link = '/checklists-med/client/' + employee.user.id + '/items/' + checklistId + '/' + checklist.name;
  //       }
  //     }
  //   });
  //
  //   if (link === '') {
  //     const checklistCompany = this.companyChecklists.find(c => c.id === checklistId);
  //     if (checklistCompany !== undefined) {
  //       link = '/checklists-company/' + checklistId + '/items/' + checklistCompany.name;
  //     }
  //   }
  //
  //   return link;
  // }
}
