import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthStore} from './authentication.reducers';
import * as fromAuthentication from './authentication.reducers';

export const selectAuthStoreFeature = createFeatureSelector<AuthStore>(fromAuthentication.authenticationFeatureKey);

export const selectFeatureLoggedIn = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.loggedIn
);

export const selectFeatureLoginFailed = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.loginFailed
);

export const selectFeatureCompanyUser = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUser
);

export const selectFeatureCompanyUserId = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUser.id
);

export const selectFeatureCompanyUserEmail = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUser.email
);

export const selectFeatureCompanyUserFirstname = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUser.firstName
);

export const selectFeatureAuthToken = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.authToken
);

export const selectCreateCompanyUser = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUserCreate
);

export const selectEmailToReset = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.emailToReset
);

export const selectCompanyId = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUser.company.id
);

export const selectCompanyUserId = createSelector(
  selectAuthStoreFeature,
  (state: AuthStore) => state.companyUser.id
);
