import {createAction, props} from '@ngrx/store';
import {ICompanyClient, IShadowUser} from '../model/shadow-user.interface';

export const addCompanyClient = createAction(
  '[Client Add] AddCompanyClient',
  props<{ newClient: IShadowUser }>()
);

export const addCompanyClientSuccess = createAction(
  '[Client Add] AddCompanyClientSuccess'
);

export const addCompanyClientFailed = createAction(
  '[Client Add] AddCompanyClientFailed'
);

export const getCompanyClientsList = createAction(
  '[Client Get All] GetCompanyClientsList'
);

export const getCompanyClientsListSuccess = createAction(
  '[Client Get All] GetCompanyClientsSuccess',
  props<{ allClients: ICompanyClient[] }>()
);

export const getCompanyClientsListFailed = createAction(
  '[Client Get All] GetCompanyClientsFailed'
);

export const getCompanyClientsListTransformDateSuccess = createAction(
  '[Client Get All] GetCompanyClientsListTransformDateSuccess',
  props<{ allClients: ICompanyClient[] }>()
);

export const getCompanyHasClientAChecklistSuccess = createAction(
  '[Client Get All] GetCompanyHasClientAChecklistSuccess',
  props<{ allClients: ICompanyClient[] }>()
);

export const getCompanyHasClientAPacklistSuccess = createAction(
  '[Client Get All] GetCompanyHasClientAPacklistSuccess',
  props<{ allClients: ICompanyClient[] }>()
);

export const deleteCompanyClient = createAction(
  '[Client Delete] DeleteCompanyClient',
  props<{ clientId: number }>()
);

export const deleteCompanyClientSuccess = createAction(
  '[Client Delete] DeleteCompanyClientSuccess'
);

export const deleteCompanyClientFailed = createAction(
  '[Client Delete] DeleteCompanyClientFailed'
);

export const updateCompanyClient = createAction(
  '[Client Update] UpdateCompanyClient',
  props<{ updateClientId: number, updateClient: ICompanyClient }>()
);

export const updateCompanyClientSuccess = createAction(
  '[Client Update] UpdateCompanyClientSuccess'
);

export const updateCompanyClientFailed = createAction(
  '[Client Update] UpdateCompanyClientFailed'
);
