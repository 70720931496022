import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AcceptDeclineDialogComponent} from './accept-decline-dialog/accept-decline-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';



@NgModule({
  declarations: [
    AcceptDeclineDialogComponent,
    InfoDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class DialogsModule { }
