<form (ngSubmit)="send()" [formGroup]="feedbackFormGroup">

  <div class="feedback-title">{{ 'TOP_BAR.FEEDBACK.POPUP_TITLE' | translate }}</div>

  <div class="feedback-form">

    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="category"
        name="category"
        placeholder="{{ 'TOP_BAR.FEEDBACK.CATEGORY_PLACEHOLDER' | translate }}"
        value=""
        [matAutocomplete]="autoProduct">
      <mat-autocomplete #autoProduct="matAutocomplete">
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
          {{category}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="feedbackFormGroup.controls['category'].hasError('required')">
        {{ 'TOP_BAR.FEEDBACK.CATEGORY_ERROR' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea
        matInput
        formControlName="subject"
        name="subject"
        placeholder="{{ 'TOP_BAR.FEEDBACK.CONTENT_PACEHOLDER' | translate }}"
        value=""
        rows="5">
        </textarea>
      <mat-error *ngIf="feedbackFormGroup.controls['subject'].hasError('required')">
        {{ 'TOP_BAR.FEEDBACK.CONTENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <button type="submit" mat-raised-button color="primary" class="feedback-button" [disabled]="feedbackFormGroup.invalid">
    {{ 'TOP_BAR.FEEDBACK.SEND_BTN' | translate }}</button>
</form>
