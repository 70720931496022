import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailComponent } from './email/email.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorSuccessToastComponent} from '../core/components/common/snackbar/error-success-toast/error-success-toast.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [EmailComponent],
    imports: [
        CommonModule,
        // RouterModule.forChild(VerifyRoutes),
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        TranslateModule,
    ],
  entryComponents: [ErrorSuccessToastComponent]
})
export class VerifyModule { }
