import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ICompanyClient, IInviteRequest, IShadowUser} from '../model/shadow-user.interface';
import {select, Store} from '@ngrx/store';
import {selectCompanyUserId} from '../../authentication/store/authentication.selector';
import {map} from 'rxjs/operators';
import {AuthStore} from '../../authentication/store/authentication.reducers';

@Injectable({
  providedIn: 'root'
})
export class ClientsHttpService {

  private companyUserId: number;

  constructor(private http: HttpClient, private authStore: Store<{ authentication: AuthStore }>) {
    this.authStore.pipe(select(selectCompanyUserId)).subscribe(companyUserId => this.companyUserId = companyUserId);
  }

  public addNewCompanyClient(companyId: number, newClient: IShadowUser): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseUrl}` + '/companies/' + companyId + '/members', newClient);
  }

  public getAllCompanyClients(companyId: number): Observable<ICompanyClient[]> {
    return this.http.get<ICompanyClient[]>(`${environment.apiBaseUrl}` + '/companies/' + companyId + '/members').pipe(
      map((employees: ICompanyClient[]) => employees.filter((employee: ICompanyClient) => employee.user.id !== this.companyUserId)),
    );
  }

  public getCompanyClientById(companyId: number, memberId: number): Observable<ICompanyClient> {
    return this.http.get<ICompanyClient>(`${environment.apiBaseUrl}/companies/${companyId}/members/${memberId}`);
  }

  public deleteCompanyClient(companyId: number, memberId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiBaseUrl}` + '/companies/' + companyId + '/members/' + memberId);
  }

  public updateCompanyClient(companyId: number, memberId: number, newClient: IShadowUser): Observable<ICompanyClient> {
    return this.http.put<ICompanyClient>(`${environment.apiBaseUrl}` + '/companies/' + companyId + '/members/' + memberId, newClient);
  }

  public inviteAppUserToCompany(companyId: number, newClient: IInviteRequest): Observable<IInviteRequest> {
    return this.http.put<IInviteRequest>(`${environment.apiBaseUrl}` + '/companies/' + companyId + '/members/invite', newClient);
  }
}
