import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  icon: {
    add: boolean;
    name: string;
  };
  buttons: {
    accept: string;
    decline: string;
  };
}

@Component({
  selector: 'app-accept-decline-dialog',
  templateUrl: './accept-decline-dialog.component.html',
  styleUrls: ['./accept-decline-dialog.component.css']
})
export class AcceptDeclineDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AcceptDeclineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
