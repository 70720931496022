import {filter} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd
} from '@angular/router';
import {Observable, Subscription} from "rxjs";
import { MatSidenav } from '@angular/material/sidenav';
import * as Ps from 'perfect-scrollbar';
import {TranslateService} from '@ngx-translate/core';
import {ICompanyUser} from '../../../../../authentication/model/company-user.interface';
import {select, Store} from '@ngrx/store';
import {AuthStore} from '../../../../../authentication/store/authentication.reducers';
import {selectFeatureCompanyUser} from '../../../../../authentication/store/authentication.selector';
import {ProfileUpdateService} from '../../../../../profile/service/profile-update.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.template.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  private isMobile;
  isSidenavOpen: Boolean = false;
  isModuleLoading: Boolean = false;
  moduleLoaderSub: Subscription;
  @ViewChild(MatSidenav, {static: true}) private sideNave: MatSidenav;

  companyUser: Observable<ICompanyUser>;
  companyUserObject: ICompanyUser;

  routerUrl = '/dashboard';

  constructor(
    private router: Router,
    public translate: TranslateService,
    private store: Store<{ authentication: AuthStore }>,
    private profileUpdateService: ProfileUpdateService) {
    // Close sidenav after route change in mobile
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange: NavigationEnd) => {
      if(this.isNavOver()) {
        this.sideNave.close();
      }
    });
  }
  ngOnInit() {

    this.router.events.subscribe((data: NavigationEnd) => {
      this.routerUrl = data.url;
    });

    // Initialize Perfect scrollbar for sidenav
    // const navigationHold = document.getElementById('scroll-area');
    // Ps.initialize(navigationHold, {
    //   suppressScrollX: true
    // });

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if(event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if(event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });

    this.companyUser = this.store.pipe(select(selectFeatureCompanyUser));

    this.companyUser.subscribe((updateCompanyUser: ICompanyUser) => {
      this.companyUserObject = updateCompanyUser;
    });

    this.profileUpdateService.getProfileSubject().subscribe((updateCompanyUser: ICompanyUser) => {
      // updateCompanyUser.profilePictureUrl = updateCompanyUser.profilePictureUrl + '?t=' +  + new Date().getTime();
      this.companyUserObject = {...updateCompanyUser};
    });
  }
  ngOnDestroy() {
    this.moduleLoaderSub.unsubscribe();
  }
  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

}
