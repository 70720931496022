import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {IClientsStore} from './clients.reducers';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {ClientsHttpService} from '../service/clients-http.service';
import {ClientType, ICompanyClient} from '../model/shadow-user.interface';
import {AuthStore} from '../../authentication/store/authentication.reducers';
import {selectCompanyId} from '../../authentication/store/authentication.selector';
import {
  addCompanyClient,
  addCompanyClientFailed,
  addCompanyClientSuccess,
  getCompanyClientsList,
  getCompanyClientsListFailed,
  getCompanyClientsListSuccess,
  deleteCompanyClient,
  deleteCompanyClientFailed,
  deleteCompanyClientSuccess,
  updateCompanyClient,
  updateCompanyClientFailed,
  updateCompanyClientSuccess,
  getCompanyClientsListTransformDateSuccess
} from './clients.actions';
import {DatePipe} from '@angular/common';

@Injectable()
export class ClientsEffects {

  addClientWebApp = createEffect(() =>
    this.actions$.pipe(
      ofType(addCompanyClient),
      mergeMap((action) => {
        return this.authStore.pipe(select(selectCompanyId)).pipe(
          switchMap((companyId: number) => this.clientsService.addNewCompanyClient(companyId, action.newClient)),
          map(() => ({type: addCompanyClientSuccess.type})),
          catchError(() => of({type: addCompanyClientFailed.type}))
        );
      })
    )
  );

  getAllClientsWebApp = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyClientsList),
      mergeMap((action) => {
        return this.authStore.pipe(select(selectCompanyId)).pipe(
          switchMap((companyId: number) => this.clientsService.getAllCompanyClients(companyId)),
          map((allCompanyClient: ICompanyClient[]) => allCompanyClient.filter((companyClient: ICompanyClient) => companyClient.type !== ClientType.Admin)),
          map((allCompanyClient: ICompanyClient[]) => allCompanyClient.filter((companyClient: ICompanyClient) => companyClient.type !==  ClientType.Owner)),
          map((allCompanyClients: ICompanyClient[]) => ({ type: getCompanyClientsListSuccess.type, allClients: allCompanyClients })),
          catchError(() => of({type: getCompanyClientsListFailed.type}))
        );
      })
    )
  );

  transformDateWebApp = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyClientsListSuccess),
      mergeMap((action) => {
        const clientsFull: ICompanyClient[] = action.allClients.map(clientFull => {
          const dateIso = new Date(clientFull.user.accountCreationDate);
          clientFull = {
            ...clientFull,
            user: {
              ...clientFull.user,
              accountCreationDateGer: this.datePipe.transform(dateIso, 'dd.MM.yyyy'),
            }
          };
          return clientFull;
        });
        return of({type: getCompanyClientsListTransformDateSuccess.type, allClients: clientsFull});
      })
    )
  );

  deleteClientWebApp = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCompanyClient),
      mergeMap((action) => {
        return this.authStore.pipe(select(selectCompanyId)).pipe(
          switchMap((companyId: number) => this.clientsService.deleteCompanyClient(companyId, action.clientId)),
          map(() => ({type: deleteCompanyClientSuccess.type})),
          catchError(() => of({type: deleteCompanyClientFailed.type}))
        );
      })
    )
  );

  updateClientWebApp = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCompanyClient),
      mergeMap((action) => {
        return this.authStore.pipe(select(selectCompanyId)).pipe(
          switchMap((companyId: number) => this.clientsService.updateCompanyClient(companyId, action.updateClient.user.id, action.updateClient.user)),
          map(() => ({type: updateCompanyClientSuccess.type})),
          catchError(() => of({type: updateCompanyClientFailed.type}))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private clientsStore: Store<{ clients: IClientsStore }>,
    private clientsService: ClientsHttpService,
    private authStore: Store<{ authentication: AuthStore }>,
    private datePipe: DatePipe,
  ) {
  }
}
