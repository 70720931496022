import {filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {RoutePartsService} from './core/services/route-parts/route-parts.service';
import {TranslateService} from '@ngx-translate/core';
import {MatIconRegistry} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import {AuthStore} from './authentication/store/authentication.reducers';
import {selectFeatureCompanyUser} from './authentication/store/authentication.selector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  appTitle = 'Wodoto';
  pageTitle = '';

  constructor(public title: Title,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private routePartsService: RoutePartsService,
              private translate: TranslateService,
              public snackBar: MatSnackBar
  ) {

    const browserLang = translate.getBrowserLang();
    const lang = browserLang.match(/en|de/) ? browserLang : 'en';
    translate.setDefaultLang(lang);
    translate.use(lang);
  }

  ngOnInit() {
    this.changePageTitle();
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
