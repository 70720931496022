<div class="main-layout">
  <topbar class="header"
    [sidenav]="sidenav"
          [notificationPanel]="notificationPanel"
    (onLangChange)="translate.use($event)"></topbar>
  <mat-sidenav-container class="side-nav-container mat-drawer-transition">
    <!-- Main side navigation -->
    <mat-sidenav #sidenav [opened]="!isNavOver()" [mode]="isNavOver() ? 'over' : 'side'"
                 class="sidebar-panel mat-elevation-z2"
                 style="background-color: transparent!important;">
      <div class="navigation-hold" fxLayout="column">

        <mat-grid-list cols="3" rowHeight="16.5px" class="nav-user-details mat-elevation-z2" style="background: rgb(0, 181, 237);">
          <mat-grid-tile
            [colspan]="3"
            [rowspan]="1">
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="1"
            [rowspan]="4"
            class="app-user">
            <div class="app-user-icon-top-left" style="margin-right: 9px;"
                 *ngIf="((companyUserObject)?.profilePictureUrl) != undefined">
              <img class="user-icon" [attr.src]="((companyUserObject)?.profilePictureUrl) | urlAddAuth | async" alt=" ">
            </div>
            <div class="app-user-icon-top-left" style="background-color: white; margin-right: 9px;"
                 *ngIf="((companyUserObject)?.profilePictureUrl) == undefined">
              {{ (companyUserObject)?.firstName | slice:0:1 }}{{ (companyUserObject)?.lastName | slice:0:1 }}
            </div>
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="2"
            [rowspan]="1"
            style="font-family: Wodoto; color: white; font-size: 13px;">
            {{ (companyUser | async)?.firstName }} {{ (companyUser | async)?.lastName }}
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="2"
            [rowspan]="1"
            style="font-family: Wodoto; color: white; font-size: 13px;">
            {{ 'SIDE_MENU.MEMBER_TITLE' | translate }} {{ (companyUser | async)?.memberNumber }}
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="2"
            [rowspan]="1"
            style="font-family: Wodoto; color: white; font-size: 13px;">
            {{ (companyUser | async)?.company.name }}
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="2"
            [rowspan]="1"
            style="font-family: Wodoto; color: white; font-size: 13px;">
            Role: Admin
          </mat-grid-tile>

          <mat-grid-tile
            [colspan]="3"
            [rowspan]="1">
          </mat-grid-tile>

        </mat-grid-list>

        <!-- Navigation -->
        <navigation class="navigation-component"></navigation>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="main-content mat-elevation-z4">

      <!--  <mat-sidenav #right position="start">-->
      <!--    Start Sidenav.-->
      <!--  </mat-sidenav>-->
      <!-- Top Bar -->


      <app-breadcrumb *ngIf="routerUrl !== '/dashboard'" style="font-family: 'wodoto-title';"></app-breadcrumb>

  <!--    <div *ngIf="routerUrl === '/dashboard'" style="margin-top: 15px;"></div>-->

      <div class="rightside-content-hold mat-elevation-z4">

        <div class="view-loader" *ngIf="isModuleLoading">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>

        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>

    <!-- Notificaation bar -->
    <!-- TODO fix the new mat-sidenav-->
    <mat-sidenav #notificationPanel mode="over" class="notification-side-nav" align="end" position="end" style="">
      <div class="navigation-hold" fxLayout="column">
        <app-notification-list [notificPanel]="notificationPanel"></app-notification-list>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
