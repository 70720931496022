import {IClientsStore} from '../store/clients.reducers';
import {ICert} from '../../certs/model/cert.interface';
import {IChecklist} from '../../checklists/model/checklist.interface';

export interface IShadowUser {
  id?: number;
  firstName: string;
  lastName: string;
  language: string;
  aboActivationDate?: string;
  accountCreationDate?: string;
  accountCreationDateGer?: string;
  birthDate?: string;
  city?: string;
  curriculumVitaeUrl?: string;
  email?: string;
  password?: string;
  folderUrl?: string;
  hoursOfRoping?: number;
  level?: string;
  memberNumber?: string;
  phoneNumber?: string;
  postalCode?: string;
  profilePictureUrl?: string;
  publicCertificate?: boolean;
  publicProfile?: boolean;
  street?: string;
  shadowUser?: boolean;
  hasChecklists?: boolean;
  hasPacklists?: boolean;
}

export interface ICompanyClient {
  companyId?: number;
  state: ClientState;
  type: ClientType;
  user: IShadowUser;
  certs?: ICert[];
  checklists?: IChecklist[];
}

export enum ClientState {
  None = '',
  Invited = 'INVITED',
  Accepted = 'ACCEPTED',
  ShadowMember = 'SHADOW_MEMBER'
}

export enum ClientType {
  None = '',
  Owner = 'OWNER',
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export const initialShadowUser: IShadowUser = {
  id: 1,
  firstName: '',
  lastName: '',
  hasChecklists: true,
  hasPacklists: true,
  language: 'de-DE'
};

export const initialCompanyClient: ICompanyClient = {
  state: ClientState.None,
  type: ClientType.None,
  user: initialShadowUser
};

export const initialClientsState: IClientsStore = {
  clients: [],
  clientsFull: []
};

export interface IInviteRequest {
  memberId?: number;
  email: string;
}
