export interface ICompanyUser {
  id: number;
  aboActivationDate?: string;
  accountCreationDate?: string;
  birthDate?: string;
  city?: string;
  curriculumVitaeUrl?: string;
  email: string;
  firstName: string;
  folderUrl?: string;
  hoursOfRoping?: number;
  lastName: string;
  level?: string;
  memberNumber?: string;
  password: string;
  phoneNumber?: string;
  postalCode?: string;
  profilePictureUrl?: string;
  publicCertificate?: boolean;
  publicProfile?: boolean;
  street?: string;
  companyUser: boolean;
  company?: ICompany;
  selectedProfilePicture?: FormData;
  language: string;
  stampPictureUuid?: string;
}

export interface ICompany {
  id?: number;
  name: string;
  street: string;
  city: string;
  postalCode: string;
  created?: string;
}

export const initialCompany: ICompany = {
  id: 0,
  name: '',
  street: '',
  city: '',
  postalCode: '',
  created: ''
}

export const initialStateCompanyUser: ICompanyUser = {
  id: 0,
  companyUser: true,
  company: initialCompany,
  firstName: '',
  lastName: '',
  street: '',
  postalCode: '',
  city: '',
  email: '',
  password: '',
  birthDate: '',
  memberNumber: '',
  phoneNumber: '',
  level: '',
  hoursOfRoping: 0,
  accountCreationDate: '',
  aboActivationDate: '',
  publicProfile: false,
  publicCertificate: false,
  folderUrl: '',
  profilePictureUrl: '',
  curriculumVitaeUrl: '',
  selectedProfilePicture: new FormData(),
  language: 'de-DE'
};

export interface ICompanyCreate {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  street: string;
  postalCode: string;
  city: string;
  language: string;
}

export const initialCompanyCreate: ICompanyCreate = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  password: '',
  street: '',
  postalCode: '',
  city: '',
  language: 'de-DE'
}
