<div class="page-wrap height-100 mat-bg-primary">
  <div class="session-form-hold">
    <mat-card>
      <mat-card-content>
        <div class="text-center pb-1">
          <div class="full-wodoto">
            <span style="font-family: 'wodoto-title'; font-size: 40px; color: #00b5ed; margin-top: 10px;">WODOTO</span>
          </div>
          <p class="text-muted m-0">{{ 'VERIFY_EMAIL.TITLE' | translate }}</p>
        </div>
        <form (ngSubmit)="verify()">

          <div class="">
            <mat-form-field class="full-width">
              <input
                matInput
                name="code"
                [formControl]="verifyForm.controls['code']"
                placeholder="{{ 'VERIFY_EMAIL.INVITE_KEY' | translate }}"
                value="">
            </mat-form-field>
          </div>

          <button mat-raised-button
                  class="mat-primary full-width mb-1"
                  class="submit-button"
                  [disabled]="verifyForm.invalid">{{ 'VERIFY_EMAIL.SUBMIT_BTN' | translate }}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

