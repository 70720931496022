import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ErrorSuccessToastComponent} from '../../core/components/common/snackbar/error-success-toast/error-success-toast.component';
import {TranslateService} from '@ngx-translate/core';
import {IAuthTokenDecoded} from '../../authentication/model/user-roles.interface';
import * as jwt from 'jsonwebtoken';
import {ILoginCredentials} from '../../authentication/model/login-credentials.interface';
import {IAuthToken} from '../../authentication/model/auth-token.interface';
import {PasswordValidator} from '../../core/validators/password.validator';
import {ConfirmEqualValidator} from '../../core/validators/confirm-equal.validator';
import {ICountries} from '../../sessions/signup/signup.component';
import {ICompanyUser} from '../../authentication/model/company-user.interface';
import {IAcceptInvitation} from '../invitation.interface';

@Component({
  selector: 'app-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.css']
})
export class AcceptComponent implements OnInit {

  acceptInvitationForm: FormGroup;

  companyId: number;
  memberId: string;
  token: string;
  inviteKey: string;
  sub: string;
  activateShadowUser = false;

  isLoggingIn = false;

  private http: HttpClient;

  signupFormGroup: FormGroup;

  availableLangs: ICountries[] = [
    {
      name: 'Deutschland',
      code: 'de-DE',
    },
    {
      name: 'Großbritannien',
      code: 'en-GB',
    }
  ];

  currentLang = 'de-DE';

  constructor(private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              handler: HttpBackend,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.http = new HttpClient(handler);
  }

  ngOnInit() {

    this.acceptInvitationForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });

    this.route.params.subscribe(params => {
      this.companyId = +params['companyId'];
    });

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      const jwtDecoded = jwt.decode(this.token, {complete: true});
      const tokenDecoded: IAuthTokenDecoded = jwtDecoded.payload;
      this.inviteKey = tokenDecoded.inviteKey;

      if (tokenDecoded.language !== undefined) {
        const languageFull = tokenDecoded.language;
        const language = languageFull.substr(0, 2);
        this.translate.use(language);
      } else {
        this.translate.use('de');
      }

      this.sub = tokenDecoded.sub;

      if (this.sub !== '0') {
        this.activateShadowUser = true;
      }
    });

    this.signupFormGroup = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidator.strong
      ]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: ConfirmEqualValidator('password', 'confirmPassword')
    });
  }

  login() {
    this.isLoggingIn = true;

    const signinData = this.acceptInvitationForm.value;

    const credentials: ILoginCredentials = {username: signinData.email, password: signinData.password};

    this.http.post<IAuthToken>(`${environment.apiBaseUrl}` + '/tokens',
      {
        email: credentials.username,
        password: credentials.password
      },
      {headers: new HttpHeaders({'Content-Type': 'application/json', Accept: '*/*'})}
    ).subscribe(
      (response: IAuthToken) => {

        const jwtDecoded = jwt.decode(response.bearer, {complete: true});
        const tokenDecoded: IAuthTokenDecoded = jwtDecoded.payload;
        this.memberId = tokenDecoded.sub;

        this.acceptInvitation(response.bearer);
      },
      (error) => {

        this.isLoggingIn = false;

        let errorMsg = this.translate.instant('COMPANY_INVITATION_ACCEPT.LOGIN_ERROR_3');

        if (error.error.errorCode.includes('INVALID_CREDENTIALS')) {
          errorMsg = this.translate.instant('COMPANY_INVITATION_ACCEPT.LOGIN_ERROR_1');
        } else if (error.error.errorCode.includes('INVALID_EMAIL')) {
          errorMsg = this.translate.instant('COMPANY_INVITATION_ACCEPT.LOGIN_ERROR_2');
        }

        this.snackBar.openFromComponent(ErrorSuccessToastComponent, {
          duration: 2000,
          panelClass: ['common-snackbar', 'error-snackbar'],
          data: errorMsg
        });
      });
  }

  private acceptInvitation(bearerToken: string) {

    const acceptInvitation: IAcceptInvitation = {
      inviteKey: this.inviteKey
    };

    this.http.put<any>(`${environment.apiBaseUrl}` + '/companies/' + this.companyId + '/members/' + this.memberId + '/accepted', acceptInvitation,
      {headers: new HttpHeaders({'Authorization': `Bearer ${bearerToken}`})}
    ).subscribe(
      (response) => {

        this.isLoggingIn = false;

        this.snackBar.openFromComponent(ErrorSuccessToastComponent, {
          duration: 2000,
          panelClass: ['common-snackbar', 'blue-snackbar'],
          data: this.translate.instant('COMPANY_INVITATION_ACCEPT.SUBMIT_TOAST_SUCCESS')
        });

        // this.router.navigate(['/sessions/signin']);
      },
      (error) => {

        this.isLoggingIn = false;

        this.snackBar.openFromComponent(ErrorSuccessToastComponent, {
          duration: 2000,
          panelClass: ['common-snackbar', 'error-snackbar'],
          data: this.translate.instant('COMPANY_INVITATION_ACCEPT.SUBMIT_TOAST_FAILED')
        });
      });
  }

  signUpAppUser() {

    this.isLoggingIn = true;
    // this.submitButton.disabled = true;

    const newAppUser: IAppUserCreate = {
      firstName: this.signupFormGroup.get('firstname').value,
      lastName: this.signupFormGroup.get('lastname').value,
      email: this.signupFormGroup.get('email').value,
      password: this.signupFormGroup.get('password').value,
      language: this.signupFormGroup.get('country').value
    };  const params = new HttpParams()
      .set('token', this.token);

    this.http.post<ICompanyUser>(`${environment.apiBaseUrl}` + '/users',
      newAppUser,
      {
        headers: new HttpHeaders({'Content-Type': 'application/json', Accept: '*/*'}),
        params: params
      },
    ).subscribe(
      (response: ICompanyUser) => {
        this.isLoggingIn = false;
        // this.router.navigate(['/sessions/signin']);
      },
      (error) => {

        this.isLoggingIn = false;
        // this.submitButton.disabled = false;

        let errorMsg = this.translate.instant('COMPANY_INVITATION_ACCEPT.LOGIN_ERROR_3');

        if (error.error.errorCode.includes('INVALID_CREDENTIALS')) {
          errorMsg = this.translate.instant('SIGNUP_APP.SEND_TOAST_FAILED_1');
        } else if (error.error.errorCode.includes('INVALID_EMAIL')) {
          errorMsg = this.translate.instant('SIGNUP_APP.SEND_TOAST_FAILED_2');
        } else if (error.error.errorCode.includes('DUPLICATED_EMAIL')) {
          errorMsg = this.translate.instant('SIGNUP_APP.SEND_TOAST_FAILED_1');
        }

        this.snackBar.openFromComponent(ErrorSuccessToastComponent, {
          duration: 2000,
          panelClass: ['common-snackbar', 'error-snackbar'],
          data: errorMsg
        });
      });
  }

}

export interface IAppUserCreate {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  street?: string;
  postalCode?: string;
  city?: string;
  language: string;
}
