import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IFeedback, IFeedbackCreateIssue, IFeedbackJiraIssueType, IFeedbackResponse} from '../model/feedback.interface';
import {FeedbackHttpService} from '../service/feedback-http.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectFeatureCompanyUser} from '../../authentication/store/authentication.selector';
import {ICompanyUser} from '../../authentication/model/company-user.interface';
import {AuthStore} from '../../authentication/store/authentication.reducers';
import {SnackBarService} from '../../core/snack-bar/snack-bar.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  feedback: IFeedback = {subject: '', recipients: [], body: '', files: []};

  feedbackFormGroup: FormGroup;

  feedbackCategoryDE: string[] = ['Account Löschanfrage', 'Bitte hinzufügen', 'Email ändern', 'Fehler', 'Frage',
    'Hinweis', 'Nachnamen ändern', 'Sonstiges', 'Vorschlag für eine neue Funktion', 'Verbesserung', ''];

  feedbackCategoryEN: string[] = ['Request to remove account', 'Please add', 'Change E-Mail', 'Mistake', 'Question',
    'Remark', 'Change last name', 'Other', 'Proposal for a new function.', 'Improvement', ''];

  usedFeedbackCategory: string[] = [''];

  filteredCategories: Observable<string[]>;

  companyUser: ICompanyUser;

  constructor(private formBuilder: FormBuilder,
              private snackBarService: SnackBarService,
              public dialogRef: MatDialogRef<FeedbackComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService,
              private feedbackHttpService: FeedbackHttpService,
              private authStore: Store<{ authentication: AuthStore }>) {
  }

  ngOnInit() {
    this.authStore.pipe(select(selectFeatureCompanyUser)).subscribe((companyUser: ICompanyUser) => {
      this.companyUser = companyUser;
    });

    const currentLang: string = this.translate.currentLang;
    this.usedFeedbackCategory = currentLang.match(/de/) ? this.feedbackCategoryDE : this.feedbackCategoryEN;

    this.feedback.recipients.push('support@wodoto.app');

    this.feedbackFormGroup = this.formBuilder.group({
      category: ['', Validators.required],
      subject: ['', Validators.required]
    });

    this.filteredCategories = this.feedbackFormGroup.get('category').valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filterCategories(value)
      })
    );
  }

  send() {
    this.feedback.subject = this.feedbackFormGroup.get('category').value;
    this.feedback.body = this.feedbackFormGroup.get('subject').value;

    let issueType;
    if (this.feedback.subject === this.feedbackCategoryDE[3] || this.feedback.subject === this.feedbackCategoryEN[3]) {
      issueType = IFeedbackJiraIssueType.BUG;
    } else if (this.feedback.subject === this.feedbackCategoryDE[8] || this.feedback.subject === this.feedbackCategoryEN[8]) {
      issueType = IFeedbackJiraIssueType.NEW_FEATURE;
    } else {
      issueType = IFeedbackJiraIssueType.IMPROVEMENT;
    }

    const jiraIssue: IFeedbackCreateIssue = {
      type: IFeedbackJiraIssueType[issueType],
      description: this.feedback.body + '\n\n User: ' + this.companyUser.firstName + ' ' + this.companyUser.lastName + '\n' + this.companyUser.email,
      title: this.feedback.subject
    };

    this.feedbackHttpService.createFeedbackJiraIssue(jiraIssue).subscribe((response: IFeedbackResponse) => {
      this.snackBarService.showInfo('TOP_BAR.FEEDBACK.SEND_FORM_TOAST_SUCCESS');
      this.dialogRef.close();
    }, error => {
      this.snackBarService.showError('TOP_BAR.FEEDBACK.SEND_FORM_TOAST_FAILED');
      this.dialogRef.close();
    });
  }

  private _filterCategories(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.usedFeedbackCategory.filter(category => category.toLowerCase().includes(filterValue));
  }
}
