import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as NotificationActions from './notification.actions';
import * as ErrorActions from '../../core/error/store/error.actions';
import {NotificationService} from '../notification.service';
import {exhaustMap} from 'rxjs-compat/operator/exhaustMap';
import {
  forgotPasswordCompanyUser,
  forgotPasswordCompanyUserFailed,
  forgotPasswordCompanyUserSuccess
} from '../../authentication/store/authentication.actions';
import {ErrorState} from '../../core/error/store/error.reducers';
import {Store} from '@ngrx/store';

@Injectable()
export class NotificationEffects {

  getNotifications = createEffect(() => {
    return this.actions.pipe(
      ofType(NotificationActions.getNotifications),
      mergeMap((action) => {
        return this.notificationService.getNotifications()
          .pipe(
            map((notifications) => (NotificationActions.getNotificationsSuccess({
              notifications: notifications
            }))),
            catchError(apiError => of(ErrorActions.handleGenericApiError({error: apiError})))
          );
      })
    );
  });

  constructor(
    private actions: Actions,
    private notificationService: NotificationService
  ) {
  }
}
