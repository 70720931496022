<mat-toolbar class="topbar topbar-white topbar-margin-right mat-elevation-z6">

  <!-- Sidenav toggle button -->
  <button
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Menü ausblenden / anzeigen">
    <mat-icon style="font-size: 30px;">menu</mat-icon>
  </button>

  <!-- Sidenav toggle collapse -->
  <!--<button-->
    <!--*ngIf="sidenav.opened"-->
    <!--mat-icon-button-->
    <!--id="collapseToggle"-->
    <!--fxHide.lt-md="true"-->
    <!--(click)="toggleCollapse()"-->
    <!--matTooltip="Nur Menü Icons anzeigen"-->
    <!--class="toggle-collapsed">-->
    <!--<mat-icon>chevron_left</mat-icon>-->
  <!--</button>-->

  <!-- Search form -->
  <div
  fxFlex
  fxHide.lt-sm="true"
  class="search-bar">
  <form class="top-search-form">
  <!--<mat-icon role="img">search</mat-icon>-->
  <!--<input autofocus="true" placeholder="Search" type="text">-->
  </form>
  </div>

  <span style="font-family: 'wodoto-title'; font-size: 43px; color: #00b5ed;" class="app-logo-text">WODOTO</span>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <mat-select
    placeholder=""
    id="langToggle"
    [style.width]="'auto'"
    name="currentLang"
    [(ngModel)]="currentLang"
    (change)="setLang($event)"
    (selectionChange)="setLang($event.value)">
    <mat-option
      *ngFor="let lang of availableLangs"
      [value]="lang.code" ngDefaultControl>{{ lang.name }}</mat-option>
  </mat-select>

  <button
    mat-icon-button
    matTooltip="{{ 'TOP_BAR.FEEDBACK.TOOL_TIP' | translate }}"
    [style.overflow]="'visible'"
    class="topbar-button-right"
    (click)="openFeedbackPopup()">
<!--    <mat-icon style="font-size: 30px;">email_outline</mat-icon>-->
    <mat-icon *ngIf="!notificationPanel.opened" svgIcon="wodoto-email"></mat-icon>
  </button>

  <button
    mat-icon-button
    matTooltip="{{ 'TOP_BAR.NEWSBOARD_TOOL_TIP' | translate }}"
    [style.overflow]="'visible'"
    class="topbar-button-right"
    (click)="toggleNotificationPanel()">
    <mat-icon *ngIf="!notificationPanel.opened" svgIcon="wodoto-notification"></mat-icon>
    <mat-icon *ngIf="notificationPanel.opened" svgIcon="wodoto-notification-active"></mat-icon>
<!--    <img src="assets/images/ic_speech_bubble.png" height="20px" alt="">-->
  </button>
  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button" style="">
    <!--<img src="assets/images/face-7.jpg" alt="">-->

<!--    <img class="user-icon" [attr.src]="((companyUser | async)?.profilePictureUrl) | urlAddAuth | async" alt=" "-->
<!--         *ngIf="((companyUser | async)?.profilePictureUrl) != undefined">-->
<!--    <div class="app-user-icon-top-right" *ngIf="((companyUser | async)?.profilePictureUrl) == undefined">-->
<!--      {{ (companyUser | async)?.firstName | slice:0:1 }}{{ (companyUser | async)?.lastName | slice:0:1 }}-->
<!--    </div>-->

    <img class="user-icon" [attr.src]="((companyUserObject)?.profilePictureUrl) | urlAddAuth | async" alt=" "
         *ngIf="((companyUserObject)?.profilePictureUrl) != undefined">
    <div class="app-user-icon-top-right" *ngIf="((companyUserObject)?.profilePictureUrl) == undefined">
      {{ (companyUserObject)?.firstName | slice:0:1 }}{{ (companyUserObject)?.lastName | slice:0:1 }}
    </div>

  </button>
  <mat-menu #accountMenu="matMenu">
    <!--<button mat-menu-item [routerLink]="['/profile/show']">-->
    <!--<mat-icon>account_box</mat-icon>-->
    <!--<span>Profil</span>-->
    <!--</button>-->
    <button mat-menu-item [routerLink]="['/profile/edit']">
      <mat-icon>settings</mat-icon>
      <span>{{ 'TOP_BAR.MENU.SETTINGS_BTN' | translate }}</span>
    </button>
    <!--<button mat-menu-item [routerLink]="['/profile/password']">-->
    <!--<mat-icon>settings</mat-icon>-->
    <!--<span>Passwort ändern</span>-->
    <!--</button>-->
    <!--<button mat-menu-item>-->
    <!--<mat-icon>notifications_off</mat-icon>-->
    <!--<span>Disable alerts</span>-->
    <!--</button>-->
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ 'TOP_BAR.MENU.LOGOUT_BTN' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
