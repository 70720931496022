export interface IPasswordChange {
  "oldPassword": string;
  "newPassword": string;
}

export interface ILoginCredentials {
  username: string;
  password: string;
  passwordChange?: IPasswordChange;
}

export const initialStatePasswordchange: IPasswordChange = {
  oldPassword: '',
  newPassword: ''
};

export const initialStateLoginCredentials: ILoginCredentials = {
  username: '',
  password: '',
  passwordChange: initialStatePasswordchange
};
